import React, { useMemo } from 'react';
import * as THREE from 'three';

const Uranus = () => {
  const uranusShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          // Apply 98-degree rotation around X axis (different from Jupiter's 30 degrees)
          float angle = radians(98.0);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - 1.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.52, 0.81, 0.85); // Blue-green base
          
          // Calculate angle around the sphere for seamless waves
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 8.0 + time * 0.3) * 0.03;
          float wave2 = sin(angle * 6.0 + time * 0.2 + PI * 1.2) * 0.02;
          float wave3 = sin(angle * 4.0 - time * 0.4 + PI * 0.6) * 0.015;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float bands = noise(vec2(modifiedY * 1.0, time * 0.1)) * 0.7;
          bands += noise(vec2(modifiedY * 20.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          if (modifiedY > 0.85) {  // Adjusted threshold
              bandColor = mix(
                  vec3(0.1, 0.3, 0.4),     // Very dark blue-green
                  vec3(0.3, 0.5, 0.6),     // Dark blue-green
                  bands
              );
          } else if (modifiedY > 0.7) {    // New layer
              bandColor = mix(
                  vec3(0.45, 0.75, 0.8),   // Medium cyan-blue
                  vec3(0.55, 0.8, 0.85),   // Lighter cyan-blue
                  bands
              );
          } else if (modifiedY > 0.55) {
              bandColor = mix(
                  vec3(0.15, 0.4, 0.25),   // Dark forest green
                  vec3(0.25, 0.5, 0.35),   // Medium forest green
                  bands
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(0.35, 0.65, 0.7),   // Light blue-green
                  vec3(0.5, 0.8, 0.85),    // Very light blue-green
                  bands
              );
          } else if (modifiedY > 0.25) {   // New layer
              bandColor = mix(
                  vec3(0.2, 0.45, 0.55),   // Dark turquoise-blue
                  vec3(0.3, 0.55, 0.65),   // Medium turquoise-blue
                  bands
              );
          } else if (modifiedY > 0.15) {
              bandColor = mix(
                  vec3(0.4, 0.7, 0.75),    // Light cyan-blue
                  vec3(0.6, 0.85, 0.9),    // Very light cyan-blue
                  bands
              );
          } else {
              bandColor = mix(
                  vec3(0.15, 0.4, 0.25),  // Very dark cyan
                  vec3(0.25, 0.5, 0.35),     // Dark cyan
                  bands
              );
          }
          
          // Add subtle tints
          bandColor *= mix(
              vec3(0.95, 1.0, 1.0),    // Slight cyan tint
              vec3(1.0, 0.98, 0.95),   // Slight warm tint
              sin(modifiedY * 8.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.2);
          
          // Calculate world position and direction to sun (0,0,0)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          // Calculate dot product between normal and direction to sun
          float dotProduct = dot(worldNormal, toSun);
          
          // Add shading spots on dark side
          if (dotProduct < 0.9) {
              float angle = acos(-dotProduct) * 2.0;
              
              // Base color
              vec4 currentColor = vec4(finalColor, 1.0);
              
              // spot (outer ring)
              if (angle < 3.3) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              
              // spot (middle ring)
              if (angle < 3.0) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }                          
              finalColor = currentColor.rgb;
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <group>
      <mesh renderOrder={2}>
        <sphereGeometry args={[25362, 32, 32]} />
        <shaderMaterial 
          attach="material"
          {...uranusShader}
          side={THREE.FrontSide}
        />
      </mesh>
    </group>
  );
};

export default Uranus;
