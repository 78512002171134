import React, { useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { Text, Preload } from '@react-three/drei';
import Earth from './Earth.js';
import gsap from 'gsap';
import Mercury from './Mercury.js';
import Venus from './Venus.js';
import Mars from './Mars.js';
import Jupiter from './Jupiter.js';
import Saturn from './Saturn.js';
import Uranus from './Uranus.js';
import Neptune from './Neptune.js';
import Sun from './Sun.js';
import BackgroundScene from './BackgroundScene.js';
import { Atmosphere } from './Atmosphere.js';
import Moon from './Moon.js';

/* -----------------------------------------------------------------
  Helper Functions
-----------------------------------------------------------------*/

// Existing helper to calculate screen position for UI.
const calculateScreenPosition = (ref, bodyName, camera) => {
  if (!ref?.current) return null;

  const position = new THREE.Vector3();
  ref.current.getWorldPosition(position);

  // Project 3D position to 2D screen space
  position.project(camera);

  // Convert to normalised device coordinates (0 to 1)
  return {
    name: bodyName,
    x: (position.x + 1) / 2,
    y: (-position.y + 1) / 2,
    z: position.z,
    visible: position.z < 1 // Object is in front of camera
  };
};

// Modified helper to compute camera rotation with a pure vertical offset.
const computeCameraRotation = (camera, target, verticalOffset) => {
  // Create a temporary camera clone and have it look at the target to get the base orientation.
  const tempCamera = camera.clone();
  tempCamera.lookAt(target);
  
  // Extract Euler angles using the "YXZ" order: yaw (Y), pitch (X) then roll (Z).
  const euler = new THREE.Euler().setFromQuaternion(tempCamera.quaternion, 'YXZ');
  
  // Adjust only the pitch (x-axis) by adding the vertical offset.
  euler.x += THREE.MathUtils.degToRad(verticalOffset);
  
  // Force roll to 0 to avoid twisting issues when the camera is below the target.
  euler.z = 0;
  
  return euler;
};

/* -----------------------------------------------------------------
     WorldSphere Component
-----------------------------------------------------------------*/
const WorldSphere = memo(({
  guidanceSystem,
  orbitLock,
  viewLock,
  gravity,
  gravityAssist,
  land,
  selectedCelestialBody,
  speed,
  timelapse,
  zoom,
  axisD,
  axisZoom,
  vertical, // new prop: vertical offset (in degrees)
  onUpdatePositions
}) => {
  const groupRef = useRef();
  const earthRef = useRef();
  const planetRefs = useRef({
    mercury: useRef(),
    venus: useRef(),
    mars: useRef(),
    jupiter: useRef(),
    saturn: useRef(),
    uranus: useRef(),
    neptune: useRef(),
  });

  const labelRefs = useRef({
    sun: useRef(),
    mercury: useRef(),
    venus: useRef(),
    earth: useRef(),
    mars: useRef(),
    jupiter: useRef(),
    saturn: useRef(),
    uranus: useRef(),
    neptune: useRef(),
  });

  const orbitRefs = useRef({
    mercury: useRef(),
    venus: useRef(),
    earth: useRef(),
    mars: useRef(),
    jupiter: useRef(),
    saturn: useRef(),
    uranus: useRef(),
    neptune: useRef(),
  });

  // Track previous selection and other parameters.
  const previousSelectionRef = useRef(selectedCelestialBody);
  const previousZoomRef = useRef(zoom);
  const previousAxisDRef = useRef(axisD);
  const previousAxisZoomRef = useRef(axisZoom);

  const { camera } = useThree();

  // Mapping of celestial body positions and view distances.
  const celestialBodies = useRef({
    Sun: { position: [0, 0, 0], viewDistance: 90000000 },
    Mercury: { position: [57900000, 0, 0], viewDistance: 100000 },
    Venus: { position: [108200000, 0, 0], viewDistance: 100000 },
    Earth: { position: [149600000, 0, 0], viewDistance: 70000 },
    Mars: { position: [227900000, 0, 0], viewDistance: 100000 },
    Jupiter: { position: [778500000, 0, 0], viewDistance: 1000000 },
    Saturn: { position: [1434000000, 0, 0], viewDistance: 1000000 },
    Uranus: { position: [2871000000, 0, 0], viewDistance: 500000 },
    Neptune: { position: [4495000000, 0, 0], viewDistance: 200000 },
    Moon: { position: [384400, 0, 0], viewDistance: 10000 } // Distance in km from Earth
  });

  // Refs for managing camera transitions
  const cameraTransitionRef = useRef(false);
  const isTransitioningRef = useRef(false);
  const originalSpeedRef = useRef(speed);

  // NEW REFS for managing tweens
  const activeTweenRef = useRef(null);
  const decelerationTweenRef = useRef(null);
  const pendingTransitionRef = useRef(null);

  // effectiveSpeedObj handles gradual powerup after a transition.
  const effectiveSpeedObj = useRef({ value: speed * timelapse });

  // Ref for initial transition flag.
  const initialTransitionDoneRef = useRef(false);

  // NEW: A ref for the current vertical offset. This is tweened to smoothly change the camera angle.
  const currentVerticalOffsetRef = useRef({ value: vertical });

  // When vertical prop updates, tween its value.
  useEffect(() => {
    gsap.to(currentVerticalOffsetRef.current, {
      value: vertical,
      duration: 4,
      ease: "power3.inOut"
    });
  }, [vertical]);

  // Constants for orbital and rotational periods (in Earth days)
  const ORBITAL_PERIODS = {
    mercury: 88,
    venus: 225,
    earth: 365,
    mars: 687,
    jupiter: 4333,
    saturn: 10759,
    uranus: 30687,
    neptune: 60190,
    moon: 27.3
  };

  const ROTATION_PERIODS = {
    mercury: 58.6,
    venus: -243, // negative for retrograde rotation
    earth: 1,
    mars: 1.025,
    jupiter: 0.417,
    saturn: 0.446,
    uranus: 0.717,
    neptune: 0.671,
    moon: 27.3
  };

  // Refs for storing pre-calculated positions
  const orbitPositionsRef = useRef({});
  const rotationPositionsRef = useRef({});
  const lastCalculationTimeRef = useRef(0);
  const PRECALCULATION_INTERVAL = 1000; // ms between position updates
  const POSITION_BUFFER_SIZE = 2; // Store current and next position for interpolation

  // Ref for the animation frame and update intervals
  const frameRef = useRef(null);
  const lastUpdateRef = useRef(0);
  const UPDATE_INTERVAL = 16; // ~60fps

  // Pre-calculate orbital velocities
  const orbitalVelocities = useRef({});
  const rotationalVelocities = useRef({});

  useEffect(() => {
    Object.keys(ORBITAL_PERIODS).forEach(planet => {
      orbitalVelocities.current[planet] = (2 * Math.PI) / (ORBITAL_PERIODS[planet] * 24 * 60 * 60);
      rotationalVelocities.current[planet] = (2 * Math.PI) / (ROTATION_PERIODS[planet] * 24 * 60 * 60);
    });
  }, []);

  // Function to calculate orbital position at a given time
  const calculateOrbitalPosition = (planet, time) => {
    const orbitalPeriod = ORBITAL_PERIODS[planet];
    const angle = (2 * Math.PI * time) / orbitalPeriod;
    return angle;
  };

  // Function to calculate rotation at a given time
  const calculateRotation = (planet, time) => {
    const rotationPeriod = ROTATION_PERIODS[planet];
    const angle = (2 * Math.PI * time) / rotationPeriod;
    return angle;
  };

  // Function to pre-calculate positions
  const updatePositionBuffer = (currentTime) => {
    const timeInDays = (currentTime * timelapse * speed) / (24 * 60 * 60);
    const nextTimeInDays = timeInDays + (PRECALCULATION_INTERVAL * timelapse * speed) / (24 * 60 * 60 * 1000);

    Object.keys(ORBITAL_PERIODS).forEach(planet => {
      if (!orbitPositionsRef.current[planet]) {
        orbitPositionsRef.current[planet] = [];
      }
      if (!rotationPositionsRef.current[planet]) {
        rotationPositionsRef.current[planet] = [];
      }

      orbitPositionsRef.current[planet] = [
        calculateOrbitalPosition(planet, timeInDays),
        calculateOrbitalPosition(planet, nextTimeInDays)
      ];

      rotationPositionsRef.current[planet] = [
        calculateRotation(planet, timeInDays),
        calculateRotation(planet, nextTimeInDays)
      ];
    });

    lastCalculationTimeRef.current = currentTime;
  };

  useEffect(() => {
    console.log('Guidance System State:', guidanceSystem);
    console.log('Orbit Lock State:', orbitLock);
    console.log('View Lock State:', viewLock);
    console.log('Gravity State:', gravity);
    console.log('Gravity Assist State:', gravityAssist);
    console.log('Land State:', land);
    console.log('Selected Body:', selectedCelestialBody);
    console.log('Speed Value:', speed);
    console.log('Timelapse Value:', timelapse);
    console.log('AxisR (Zoom) Value:', zoom);
    console.log('AxisD Value:', axisD);
  }, [
    guidanceSystem,
    orbitLock,
    viewLock,
    gravity,
    gravityAssist,
    land,
    selectedCelestialBody,
    speed,
    timelapse,
    zoom,
    axisD
  ]);

  useEffect(() => {
    Object.values(labelRefs.current).forEach(ref => {
      if (ref.current) {
        ref.current.visible = guidanceSystem;
      }
    });
  }, [guidanceSystem]);

  /* -----------------------------------------------------------------
                         Camera Transition Function
     This function sets up a tween (using GSAP) to move the camera and
     update its orientation smoothly. The vertical offset (if any) is
     incorporated when computing the final target rotation.
  ----------------------------------------------------------------- */
  const initiateCameraTransition = (body) => {
    let targetRef;
    let planetPosition = new THREE.Vector3();
    let targetPosition;

    // Get base view distance
    const baseViewDistance = celestialBodies.current[body]?.viewDistance || 70000;

    if (body === 'Sun') {
      planetPosition.set(0, 0, 0);
      targetPosition = new THREE.Vector3(5000000, 0, 0);
    } else if (body === 'Moon') {
      if (!earthRef.current || !moonRef.current) return;
      const earthPosition = new THREE.Vector3();
      const moonPosition = new THREE.Vector3();
      earthRef.current.getWorldPosition(earthPosition);
      moonRef.current.getWorldPosition(moonPosition);
      
      const directionVector = moonPosition.clone().sub(earthPosition).normalize();
      targetPosition = earthPosition.clone().add(directionVector.multiplyScalar(baseViewDistance));
      planetPosition = moonPosition.clone();
    } else {
      targetRef = body === 'Earth' ? earthRef : planetRefs.current[body.toLowerCase()];
      if (!targetRef?.current) return;
      
      targetRef.current.getWorldPosition(planetPosition);
      const initialOrbitAngle = targetRef.current.userData.orbitStartAngle || 0;
      const cameraOrbitSpeedFactor = 1.00274;
      const currentRotationOffset = targetRef.current.rotation.y * cameraOrbitSpeedFactor;
      const horizontalInput = (zoom * Math.PI) / 180;
      const adjustedAngle = initialOrbitAngle - currentRotationOffset + horizontalInput;
      const verticalAngle = (axisD * Math.PI) / 180;
      const adjustedViewDistance = baseViewDistance / (typeof axisZoom === 'number' ? axisZoom : 1);
      
      targetPosition = new THREE.Vector3(
        planetPosition.x + adjustedViewDistance * Math.cos(verticalAngle) * Math.cos(adjustedAngle),
        planetPosition.y + adjustedViewDistance * Math.sin(verticalAngle),
        planetPosition.z + adjustedViewDistance * Math.cos(verticalAngle) * Math.sin(adjustedAngle)
      );
    }

    // If first transition, teleport camera immediately
    if (!initialTransitionDoneRef.current) {
      const tempCamera = camera.clone();
      tempCamera.position.copy(targetPosition);
      tempCamera.lookAt(planetPosition);
      let targetRotation = tempCamera.quaternion.clone();

      // Modified vertical offset application
      if (vertical && vertical !== 0) {
        const lookVector = new THREE.Vector3().subVectors(planetPosition, tempCamera.position);
        const right = new THREE.Vector3().crossVectors(lookVector, tempCamera.up).normalize();
        const rad = THREE.MathUtils.degToRad(vertical);
        lookVector.applyAxisAngle(right, rad);
        tempCamera.lookAt(tempCamera.position.clone().add(lookVector));
        targetRotation.copy(tempCamera.quaternion);
      }

      camera.position.copy(targetPosition);
      camera.quaternion.copy(targetRotation);

      if (body !== 'Sun' && targetRef?.current) {
        const finalPlanetPos = new THREE.Vector3();
        targetRef.current.getWorldPosition(finalPlanetPos);
        const dx = camera.position.x - finalPlanetPos.x;
        const dz = camera.position.z - finalPlanetPos.z;
        const thetaFinal = Math.atan2(dz, dx);
        const cameraOrbitSpeedFactor = 1.00274;
        const horizontalAngle = (zoom * Math.PI) / 180;
        targetRef.current.userData.orbitStartAngle = thetaFinal + (targetRef.current.rotation.y * cameraOrbitSpeedFactor) - horizontalAngle;
      }
      previousSelectionRef.current = body;
      initialTransitionDoneRef.current = true;
      return;
    }

    // For transitions with GSAP tween, pause timelapse.
    gsap.killTweensOf(effectiveSpeedObj.current);
    effectiveSpeedObj.current.value = 0;

    cameraTransitionRef.current = true;
    isTransitioningRef.current = true;
    const startPosition = camera.position.clone();
    const startQuat = camera.quaternion.clone(); // Store starting quaternion

    // Set up target camera and get its base orientation
    const tempCamera = camera.clone();
    tempCamera.position.copy(targetPosition);
    tempCamera.lookAt(planetPosition);

    // Get base target rotation
    const baseTargetEuler = new THREE.Euler().setFromQuaternion(tempCamera.quaternion, 'YXZ');

    // Create final target Euler by only modifying the pitch
    const targetEuler = baseTargetEuler.clone();
    const verticalRad = THREE.MathUtils.degToRad(vertical || 0);
    targetEuler.x = baseTargetEuler.x + verticalRad;
    targetEuler.y = baseTargetEuler.y;
    targetEuler.z = 0;

    // Compute target quaternion from the modified Euler
    const targetQuat = new THREE.Quaternion().setFromEuler(targetEuler);

    const progress = { value: 0 };

    activeTweenRef.current = gsap.to(progress, {
      value: 1,
      duration: 4,
      ease: "power3.inOut",
      onUpdate: () => {
        const t = progress.value;
        
        // For Earth transitions, use spherical interpolation so that the camera
        // moves along an arc around Earth's center and does not go through Earth.
        if (body === 'Earth') {
          // Use the already-calculated planetPosition as the Earth's center.
          const earthCenter = planetPosition;
          
          // Calculate the vectors from Earth's center to the starting and target positions.
          const startVec = startPosition.clone().sub(earthCenter);
          const targetVec = targetPosition.clone().sub(earthCenter);
          
          // Get the distances (this allows for a possible difference in camera distance).
          const startLength = startVec.length();
          const targetLength = targetVec.length();
          
          // Linearly interpolate the distances.
          const currentDistance = THREE.MathUtils.lerp(startLength, targetLength, t);
          
          // Normalize the direction vectors.
          startVec.normalize();
          targetVec.normalize();
          
          // Compute the angle between the two directions.
          const dot = THREE.MathUtils.clamp(startVec.dot(targetVec), -1, 1);
          const omega = Math.acos(dot);
          
          let currentDirection;
          if (omega < 0.001) {
            // If the vectors are almost identical, just use the start direction.
            currentDirection = startVec.clone();
          } else {
            // Perform spherical interpolation between startVec and targetVec.
            const sinOmega = Math.sin(omega);
            const factor1 = Math.sin((1 - t) * omega) / sinOmega;
            const factor2 = Math.sin(t * omega) / sinOmega;
            currentDirection = startVec.clone().multiplyScalar(factor1).add(
              targetVec.clone().multiplyScalar(factor2)
            ).normalize();
          }
          
          // Reconstruct the new camera position.
          const newPosition = earthCenter.clone().add(currentDirection.multiplyScalar(currentDistance));
          camera.position.copy(newPosition);
          
        } else {
          // For other bodies, do the usual linear interpolation.
          camera.position.lerpVectors(startPosition, targetPosition, t);
        }
        
        // Always interpolate the quaternion smoothly.
        camera.quaternion.slerpQuaternions(startQuat, targetQuat, t);
      },
      onComplete: () => {
        if (body !== 'Sun' && targetRef?.current) {
          const finalPlanetPos = new THREE.Vector3();
          targetRef.current.getWorldPosition(finalPlanetPos);
          const dx = camera.position.x - finalPlanetPos.x;
          const dz = camera.position.z - finalPlanetPos.z;
          const thetaFinal = Math.atan2(dz, dx);
          const cameraOrbitSpeedFactor = 1.00274;
          const horizontalAngle = (zoom * Math.PI) / 180;
          targetRef.current.userData.orbitStartAngle = thetaFinal + (targetRef.current.rotation.y * cameraOrbitSpeedFactor) - horizontalAngle;
        }
        previousSelectionRef.current = body;
        cameraTransitionRef.current = false;
        isTransitioningRef.current = false;
        activeTweenRef.current = null;

        gsap.to(effectiveSpeedObj.current, {
          value: speed * timelapse,
          duration: 2,
          ease: "power3.inOut"
        });

        if (pendingTransitionRef.current && pendingTransitionRef.current !== body) {
          const newBody = pendingTransitionRef.current;
          pendingTransitionRef.current = null;
          initiateCameraTransition(newBody);
        }
      }
    });
  };

  /* -----------------------------------------------------------------
      Modified useEffect for camera transitions when selected celestial
      body or rotation / zoom parameters change.
  -----------------------------------------------------------------*/
  useEffect(() => {
    if (
      selectedCelestialBody !== previousSelectionRef.current ||
      zoom !== previousZoomRef.current ||
      axisD !== previousAxisDRef.current ||
      axisZoom !== previousAxisZoomRef.current
    ) {
      if (isTransitioningRef.current && activeTweenRef.current) {
        pendingTransitionRef.current = selectedCelestialBody;
        activeTweenRef.current.kill();
        activeTweenRef.current = null;
        decelerationTweenRef.current = gsap.to({}, {
          duration: 1,
          ease: "power3.out",
          onComplete: () => {
            if (pendingTransitionRef.current) {
              initiateCameraTransition(pendingTransitionRef.current);
              pendingTransitionRef.current = null;
            }
          }
        });
      } else {
        initiateCameraTransition(selectedCelestialBody);
      }
      previousSelectionRef.current = selectedCelestialBody;
      previousZoomRef.current = zoom;
      previousAxisDRef.current = axisD;
      previousAxisZoomRef.current = axisZoom;
    }
  }, [selectedCelestialBody, zoom, axisD, axisZoom, camera]);

  // Keep effectiveSpeed in sync when speed or timelapse change when not transitioning.
  useEffect(() => {
    if (!isTransitioningRef.current) {
      effectiveSpeedObj.current.value = speed * timelapse;
    }
  }, [speed, timelapse]);

  /* -----------------------------------------------------------------
      Initial camera positioning useEffect.
  -----------------------------------------------------------------*/
  useEffect(() => {
    if (selectedCelestialBody === 'Earth' && zoom === 0 && axisD === 0) {
      const planetPosition = new THREE.Vector3();
      earthRef.current.getWorldPosition(planetPosition);
      
      const offset = celestialBodies.current['Earth'].viewDistance;
      const targetPosition = new THREE.Vector3(
        planetPosition.x - offset,
        planetPosition.y + 5000,
        planetPosition.z + 80000
      );

      camera.position.copy(targetPosition);
      
      // Use the new computeCameraRotation instead
      const rotation = computeCameraRotation(camera, planetPosition, currentVerticalOffsetRef.current.value);
      camera.rotation.copy(rotation);
      
      // Store the initial orbit angle for further rotation calcs.
      const dx = camera.position.x - planetPosition.x;
      const dz = camera.position.z - planetPosition.z;
      earthRef.current.userData.orbitStartAngle = Math.atan2(dz, dx) + earthRef.current.rotation.y;
    }
  }, []); // Run once on mount

  // Add Moon ref
  const moonRef = useRef();
  const moonOrbitRef = useRef();

  // Additional refs for Moon
  const moonTimeRef = useRef(0);
  const FIXED_TIME_STEP = 1 / 60; // 60fps fixed time step

  useFrame(({ clock, camera }) => {
    const currentTime = clock.getElapsedTime() * 1000;
    
    if (currentTime - lastUpdateRef.current < UPDATE_INTERVAL) {
      return;
    }

    const deltaTime = (currentTime - lastUpdateRef.current) / 1000;
    lastUpdateRef.current = currentTime;

    const currentEffectiveSpeed = effectiveSpeedObj.current.value;

    // Batch updates for orbits and rotations.
    const updates = {
      orbits: {},
      rotations: {}
    };

    Object.entries(orbitalVelocities.current).forEach(([planet, velocity]) => {
      updates.orbits[planet] = velocity * deltaTime * currentEffectiveSpeed;
      updates.rotations[planet] = rotationalVelocities.current[planet] * deltaTime * currentEffectiveSpeed;
    });

    Object.entries(updates.orbits).forEach(([planet, angle]) => {
      if (orbitRefs.current[planet]?.current) {
        orbitRefs.current[planet].current.rotation.y += angle;
      }
    });

    Object.entries(updates.rotations).forEach(([planet, angle]) => {
      const ref = planet === 'earth' ? earthRef : planetRefs.current[planet];
      if (ref?.current) {
        ref.current.rotation.y += angle;
      }
    });

    // Update Moon orbit BEFORE updating the camera.
    if (moonOrbitRef.current && moonRef.current) {
      moonTimeRef.current += FIXED_TIME_STEP * currentEffectiveSpeed;
      
      const moonVelocity = (2 * Math.PI) / (ORBITAL_PERIODS.moon * 24 * 60 * 60);
      const orbitAngle = moonTimeRef.current * moonVelocity;
      
      moonOrbitRef.current.rotation.y = orbitAngle % (2 * Math.PI);
      moonRef.current.rotation.y = 0;
      
      if (moonTimeRef.current > 1000) {
        moonTimeRef.current = moonTimeRef.current % (ORBITAL_PERIODS.moon * 24 * 60 * 60);
      }
    }

    // Update camera only when not in a transition
    if (!cameraTransitionRef.current && selectedCelestialBody) {
      if (selectedCelestialBody === 'Sun') {
        // Sun branch (if needed)
      } else if (selectedCelestialBody === 'Moon') {
        if (earthRef?.current && moonRef?.current) {
          const earthPosition = new THREE.Vector3();
          const moonPosition = new THREE.Vector3();
          earthRef.current.getWorldPosition(earthPosition);
          moonRef.current.getWorldPosition(moonPosition);
          
          const directionVector = moonPosition.clone().sub(earthPosition).normalize();
          const baseViewDistance = celestialBodies.current['Moon'].viewDistance;
          const adjustedViewDistance = baseViewDistance / (typeof axisZoom === 'number' ? axisZoom : 1);
          
          const cameraPosition = earthPosition.clone().add(directionVector.multiplyScalar(adjustedViewDistance));
          camera.position.copy(cameraPosition);
          
          // Apply rotation directly instead of using lookAt
          const rotation = computeCameraRotation(camera, moonPosition, currentVerticalOffsetRef.current.value);
          camera.rotation.copy(rotation);
        }
      } else {
        const targetRef = selectedCelestialBody === 'Earth'
          ? earthRef
          : planetRefs.current[selectedCelestialBody.toLowerCase()];
        
        if (targetRef?.current) {
          const planetPosition = new THREE.Vector3();
          targetRef.current.getWorldPosition(planetPosition);
          
          const baseViewDistance = celestialBodies.current[selectedCelestialBody].viewDistance;
          const adjustedViewDistance = baseViewDistance / (typeof axisZoom === 'number' ? axisZoom : 1);
          
          const horizontalAngle = (zoom * Math.PI) / 180;
          const verticalAngle = (axisD * Math.PI) / 180;
          
          const startAngle = targetRef.current.userData.orbitStartAngle || 0;
          const cameraOrbitSpeedFactor = 1.00274;
          const angle = startAngle - (targetRef.current.rotation.y * cameraOrbitSpeedFactor) + horizontalAngle;

          camera.position.set(
            planetPosition.x + adjustedViewDistance * Math.cos(verticalAngle) * Math.cos(angle),
            planetPosition.y + adjustedViewDistance * Math.sin(verticalAngle),
            planetPosition.z + adjustedViewDistance * Math.cos(verticalAngle) * Math.sin(angle)
          );
          
          // Apply rotation directly instead of using lookAt
          const rotation = computeCameraRotation(camera, planetPosition, currentVerticalOffsetRef.current.value);
          camera.rotation.copy(rotation);
        }
      }
    }

    // Update labels less frequently.
    if (guidanceSystem && (currentTime % 100 < UPDATE_INTERVAL)) {
      Object.values(labelRefs.current).forEach(ref => {
        if (ref.current) {
          ref.current.lookAt(camera.position);
        }
      });
    }

    // Update UI positions less frequently.
    if (currentTime % 500 < UPDATE_INTERVAL) {
      const positions = calculatePositionsForUI(camera);
      if (typeof onUpdatePositions === 'function') {
        onUpdatePositions(positions);
      }
    }
  });

  // Helper function to calculate positions for UI.
  const calculatePositionsForUI = (camera) => {
    const positions = {};
    const frustum = new THREE.Frustum();
    const projScreenMatrix = new THREE.Matrix4();
    projScreenMatrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
    frustum.setFromProjectionMatrix(projScreenMatrix);

    positions.sun = calculateScreenPosition(groupRef, 'Sun', camera);
    positions.earth = calculateScreenPosition(earthRef, 'Earth', camera);

    Object.entries(planetRefs.current).forEach(([planet, ref]) => {
      positions[planet] = calculateScreenPosition(ref, planet.charAt(0).toUpperCase() + planet.slice(1), camera);
    });

    return positions;
  };

  return (
    <>
      <Preload all />
      <BackgroundScene speed={isTransitioningRef.current ? 0 : timelapse} />
      <group position={[0, 0, 0]}>
        <Text
          ref={labelRefs.current.sun}
          position={[0, 10000000, 0]}
          fontSize={5000000}
          colour="white"
          anchorX="center"
          anchorY="bottom"
          renderOrder={1}
          depthWrite={true}
          depthTest={true}
        >
          {""}
        </Text>
        <Sun isSelected={selectedCelestialBody === 'Sun'} />

        {/* Mercury */}
        <group ref={orbitRefs.current.mercury}>
          <group position={[57900000, 0, 0]} ref={planetRefs.current.mercury}>
            <Text
              ref={labelRefs.current.mercury}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Mercury />
          </group>
        </group>

        {/* Venus */}
        <group ref={orbitRefs.current.venus}>
          <group position={[108200000, 0, 0]} ref={planetRefs.current.venus}>
            <Text
              ref={labelRefs.current.venus}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Venus />
          </group>
        </group>

        {/* Earth and its Moon */}
        <group ref={orbitRefs.current.earth}>
          <group position={[149600000, 0, 0]} ref={groupRef}>
            <Text
              ref={labelRefs.current.earth}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <group ref={earthRef}>
              <Atmosphere renderOrder={5} />
              <Earth renderOrder={6} depthWrite={true} depthTest={true} />
              <group ref={moonOrbitRef}>
                <group position={[384400, 0, 0]} ref={moonRef}>
                  <Moon scale={[0.2725, 0.2725, 0.2725]} />
                </group>
              </group>
            </group>
          </group>
        </group>

        {/* Mars */}
        <group ref={orbitRefs.current.mars}>
          <group position={[227900000, 0, 0]} ref={planetRefs.current.mars}>
            <Text
              ref={labelRefs.current.mars}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Mars />
          </group>
        </group>

        {/* Jupiter */}
        <group ref={orbitRefs.current.jupiter}>
          <group position={[778500000, 0, 0]} ref={planetRefs.current.jupiter}>
            <Text
              ref={labelRefs.current.jupiter}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Jupiter />
          </group>
        </group>

        {/* Saturn */}
        <group ref={orbitRefs.current.saturn}>
          <group position={[1434000000, 0, 0]} ref={planetRefs.current.saturn}>
            <Text
              ref={labelRefs.current.saturn}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Saturn />
            <mesh rotation={[Math.PI / 6, 0, 0]} />
          </group>
        </group>

        {/* Uranus */}
        <group ref={orbitRefs.current.uranus}>
          <group position={[2871000000, 0, 0]} ref={planetRefs.current.uranus}>
            <Text
              ref={labelRefs.current.uranus}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Uranus />
          </group>
        </group>

        {/* Neptune */}
        <group ref={orbitRefs.current.neptune}>
          <group position={[4495000000, 0, 0]} ref={planetRefs.current.neptune}>
            <Text
              ref={labelRefs.current.neptune}
              position={[0, 50000, 0]}
              fontSize={25000}
              colour="white"
              anchorX="center"
              anchorY="bottom"
              renderOrder={10000}
              depthWrite={false}
            >
              {""}
            </Text>
            <Neptune />
          </group>
        </group>
      </group>
    </>
  );
});

WorldSphere.propTypes = {
  guidanceSystem: PropTypes.bool,
  orbitLock: PropTypes.bool,
  viewLock: PropTypes.bool,
  gravity: PropTypes.bool,
  gravityAssist: PropTypes.bool,
  land: PropTypes.bool,
  selectedCelestialBody: PropTypes.string,
  speed: PropTypes.number,
  timelapse: PropTypes.number,
  zoom: PropTypes.number,
  axisD: PropTypes.number,
  axisZoom: PropTypes.number,
  vertical: PropTypes.number,
  onUpdatePositions: PropTypes.func,
};

WorldSphere.defaultProps = {
  guidanceSystem: false,
  orbitLock: false,
  viewLock: false,
  gravity: true,
  gravityAssist: false,
  land: false,
  selectedCelestialBody: 'Earth',
  speed: 50,
  timelapse: 0,
  zoom: 0,
  axisD: 0,
  axisZoom: 1,
  vertical: 0, // default vertical offset is 0 (no change)
  onUpdatePositions: () => {},
};

export default WorldSphere;
