import React, { useMemo } from 'react';
import * as THREE from 'three';

const Venus = () => {
  const venusShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          float angle = radians(177.3);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - 1.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.95, 0.87, 0.26); // #F2DF42 converted to RGB
          
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 8.0 + time * 0.15) * 0.025;
          float wave2 = sin(angle * 5.0 + time * 0.1 + PI * 0.6) * 0.02;
          float wave3 = sin(angle * 3.0 - time * 0.2 + PI * 0.3) * 0.015;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float clouds = noise(vec2(modifiedY * 3.0, time * 0.1)) * 0.7;
          clouds += noise(vec2(modifiedY * 8.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          if (modifiedY > 0.9) {
              bandColor = mix(
                  vec3(1.0, 0.95, 0.2),         // Intense yellow
                  vec3(1.0, 0.98, 0.3),         // Bright lemon yellow
                  clouds
              );
          } else if (modifiedY > 0.8) {
              bandColor = mix(
                  vec3(1.0, 0.6, 0.0),          // Pure orange
                  vec3(1.0, 0.7, 0.1),          // Bright orange
                  clouds
              );
          } else if (modifiedY > 0.7) {
              bandColor = mix(
                  vec3(0.95, 0.87, 0.26),       // #F2DF42
                  vec3(1.0, 0.9, 0.4),          // Light golden yellow
                  clouds
              );
          } else if (modifiedY > 0.6) {
              bandColor = mix(
                  vec3(0.9, 0.4, 0.0),          // Deep orange
                  vec3(1.0, 0.5, 0.1),          // Bright orange-red
                  clouds
              );
          } else if (modifiedY > 0.5) {
              bandColor = mix(
                  vec3(1.0, 0.9, 0.2),          // Bright yellow
                  vec3(1.0, 0.85, 0.3),         // Golden yellow
                  clouds
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(1.0, 0.65, 0.1),         // Golden orange
                  vec3(1.0, 0.75, 0.2),         // Light golden orange
                  clouds
              );
          } else if (modifiedY > 0.3) {
              bandColor = mix(
                  vec3(0.95, 0.8, 0.2),         // Warm yellow
                  vec3(1.0, 0.9, 0.3),          // Bright warm yellow
                  clouds
              );
          } else if (modifiedY > 0.2) {
              bandColor = mix(
                  vec3(0.8, 0.3, 0.0),          // Dark orange
                  vec3(0.9, 0.4, 0.1),          // Rich orange
                  clouds
              );
          } else if (modifiedY > 0.1) {
              bandColor = mix(
                  vec3(1.0, 0.95, 0.2),         // Bright yellow
                  vec3(0.95, 0.87, 0.26),       // #F2DF42
                  clouds
              );
          } else {
              bandColor = mix(
                  vec3(1.0, 0.7, 0.1),          // Bright orange
                  vec3(1.0, 0.8, 0.2),          // Light orange-yellow
                  clouds
              );
          }
          
          // More dynamic tints
          bandColor *= mix(
              vec3(1.4, 1.3, 1.1),          // Super bright highlight
              vec3(1.0, 0.9, 0.7),          // Warm shadow tone
              sin(modifiedY * 7.0 + clouds * 2.0) * 0.5 + 0.5  // Added clouds influence
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.01); // Minimal mixing for maximum contrast
          
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          float dotProduct = dot(worldNormal, toSun);
          
          if (dotProduct < 0.9) {
              float angle = acos(-dotProduct) * 2.0;
              vec4 currentColor = vec4(finalColor, 1.0);
              
              if (angle < 3.3) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              if (angle < 3.0) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              
              
              finalColor = currentColor.rgb;
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <group>
      <mesh renderOrder={2}>
        <sphereGeometry args={[6051.8, 32, 32]} />
        <shaderMaterial 
          attach="material"
          {...venusShader}
          side={THREE.FrontSide}
        />
      </mesh>

    </group>
  );
};

export default Venus;
