import React, { useMemo } from 'react';
import * as THREE from 'three';

const Neptune = () => {
  const neptuneShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          vUv = uv;
          vNormal = normal;
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }

        // Add noise function for the stripes
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (3.0 - 2.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.0, 0.35, 0.85); // Deep blue base
          
          // Calculate angle around the sphere for seamless waves
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 10.0 + time * 0.4) * 0.04;
          float wave2 = sin(angle * 8.0 + time * 0.3 + 3.14159 * 1.5) * 0.03;
          float wave3 = sin(angle * 6.0 - time * 0.5 + 3.14159 * 0.8) * 0.02;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          // Enhanced stripe system
          float bands = noise(vec2(modifiedY * 1.0, time * 0.1)) * 0.7;
          bands += noise(vec2(modifiedY * 30.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          // Create distinct bands with spot-matching colors
          if (modifiedY > 0.8) {
              bandColor = mix(
                  vec3(0.0, 0.5, 0.7),    // Cyan-blue
                  vec3(0.0, 0.6, 0.8),    // Lighter cyan-blue
                  bands
              );
          } else if (modifiedY > 0.6) {
              bandColor = mix(
                  vec3(0.0, 0.6, 0.5),    // Greenish-teal
                  vec3(0.0, 0.7, 0.6),    // Lighter greenish-teal
                  bands
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(0.1, 0.5, 0.5),    // Balanced blue-green
                  vec3(0.1, 0.6, 0.6),    // Lighter blue-green
                  bands
              );
          } else if (modifiedY > 0.2) {
              bandColor = mix(
                  vec3(0.0, 0.4, 0.7),    // Deep turquoise
                  vec3(0.0, 0.5, 0.8),    // Lighter turquoise
                  bands
              );
          } else {
              bandColor = mix(
                  vec3(0.0, 0.45, 0.4),   // Deep sea green
                  vec3(0.0, 0.55, 0.5),   // Lighter sea green
                  bands
              );
          }
          
          // Mix the bands with base color more prominently
          vec3 finalColor = mix(baseColor, bandColor, 0.8);  // Increased to 0.8 for more distinction
          
          // Calculate spherical coordinates for spots
          float phi = atan(vPosition.z, vPosition.x);
          float theta = acos(vPosition.y / length(vPosition));
          
          for(int i = 0; i < 168; i++) {  // Increased from 120 to 168 circles
              // Modified random seeds to create more variation in distribution
              float randPhi = random(vec2(float(i) * 0.1567, 0.3291)) * 2.0 * 3.14159;
              float randTheta = random(vec2(float(i) * 0.7823, 0.9451)) * 3.14159;
              
              float angularDist = acos(
                sin(theta) * sin(randTheta) * cos(phi - randPhi) + 
                cos(theta) * cos(randTheta)
              );
              
              // Reduced size range for smaller spots (50% of original)
              float size = (0.15 + 0.25 * random(vec2(float(i) * 0.789, 0.32))) * 0.5;
              
              float spotOuter = step(angularDist, size);
              float spotInner = step(angularDist, size * 0.7);
              
              float colorChoice = random(vec2(float(i) * 0.345, 0.678));
              vec3 outerColor, innerColor;
              
              // More color variations with greener tints
              if (colorChoice < 0.2) {
                  outerColor = vec3(0.0, 0.5, 0.7);    // Cyan-blue
                  innerColor = vec3(0.0, 0.6, 0.8);    // Lighter cyan-blue
              } else if (colorChoice < 0.4) {
                  outerColor = vec3(0.0, 0.6, 0.5);    // Greenish-teal
                  innerColor = vec3(0.0, 0.7, 0.6);    // Lighter greenish-teal
              } else if (colorChoice < 0.6) {
                  outerColor = vec3(0.1, 0.5, 0.5);    // Balanced blue-green
                  innerColor = vec3(0.1, 0.6, 0.6);    // Lighter blue-green
              } else if (colorChoice < 0.8) {
                  outerColor = vec3(0.0, 0.4, 0.7);    // Deep turquoise
                  innerColor = vec3(0.0, 0.5, 0.8);    // Lighter turquoise
              } else {
                  outerColor = vec3(0.0, 0.45, 0.4);   // Deep sea green
                  innerColor = vec3(0.0, 0.55, 0.5);   // Lighter sea green
              }
              
              finalColor = mix(finalColor, outerColor, spotOuter * 1.0);
              finalColor = mix(finalColor, innerColor, spotInner * 1.0);
          }
          
          // Calculate world position and direction to sun (0,0,0)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          // Calculate dot product between normal and direction to sun
          float dotProduct = dot(worldNormal, toSun);
          
          // Add shading spots on dark side
          if (dotProduct < 0.9) {
              float angle = acos(-dotProduct) * 2.0;
              
              // Base color
              vec4 currentColor = vec4(finalColor, 1.0);
              
              // spot (outer ring)
              if (angle < 3.3) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              
              // spot (middle ring)
              if (angle < 3.0) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }              
              finalColor = currentColor.rgb;
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <group>
      <mesh renderOrder={2}>
        <sphereGeometry args={[24622, 32, 32]} />
        <shaderMaterial 
          attach="material"
          {...neptuneShader}
          side={THREE.FrontSide}
        />
      </mesh>

      {/* Outer atmosphere layer */}
      <mesh renderOrder={1}>
        <sphereGeometry args={[25800, 128, 640]} />
        <meshBasicMaterial 
          color="#4169E1"
          transparent={true}
          opacity={0.15}
          side={THREE.BackSide}
          depthWrite={false}
          depthTest={true}
        />
      </mesh>

      {/* Inner atmosphere layer */}
      <mesh renderOrder={-1}>
        <sphereGeometry args={[25000, 128, 640]} />
        <meshBasicMaterial 
          color="#4169E1"
          transparent={true}
          opacity={0.15}
          side={THREE.BackSide}
          depthWrite={false}
          depthTest={true}
        />
      </mesh>
    </group>
  );
};

export default Neptune;
