import React, { useMemo } from 'react';
import * as THREE from 'three';

const Jupiter = () => {
  const jupiterShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          // Apply 30-degree rotation around X axis
          float angle = radians(30.0);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        // Noise functions
        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        // Value noise
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - 1.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.698, 0.849, 0.078); // #B2D914 in RGB
          
          // Calculate angle around the sphere for seamless waves
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 10.0 + time * 0.4) * 0.04;
          float wave2 = sin(angle * 8.0 + time * 0.3 + PI * 1.5) * 0.03;
          float wave3 = sin(angle * 6.0 - time * 0.5 + PI * 0.8) * 0.02;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float bands = noise(vec2(modifiedY * 1.0, time * 0.1)) * 0.7;
          bands += noise(vec2(modifiedY * 30.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          // Doubled number of bands
          if (modifiedY > 0.92) {
              // Purple-tinted band
              bandColor = mix(
                  vec3(0.6, 0.2, 0.8),    // Deep purple
                  vec3(0.9, 0.7, 1.0),    // Light purple
                  bands
              );
          } else if (modifiedY > 0.84) {
              // Cyan band
              bandColor = mix(
                  vec3(0.2, 0.8, 0.8),    // Deep cyan
                  vec3(0.6, 0.9, 0.9),    // Light cyan
                  bands
              );
          } else if (modifiedY > 0.76) {
              // Magenta-purple band
              bandColor = mix(
                  vec3(0.8, 0.2, 0.6),    // Deep magenta
                  vec3(1.0, 0.5, 0.8),    // Light pink
                  bands
              );
          } else if (modifiedY > 0.68) {
              // Default light band
              bandColor = mix(
                  vec3(0.47, 0.56, 0.77),   // #7990C6
                  vec3(0.035, 1.0, 0.482),    // #09FF7B
                  bands
              );
          } else if (modifiedY > 0.60) {
              // Purple-tinted band
              bandColor = mix(
                  vec3(0.6, 0.2, 0.8),    // Deep purple
                  vec3(0.9, 0.7, 1.0),    // Light purple
                  bands
              );
          } else if (modifiedY > 0.55) {  // Thin orange band
              // Orange-red band
              bandColor = mix(
                  vec3(0.9, 0.3, 0.1),    // Deep orange-red
                  vec3(1.0, 0.6, 0.3),    // Light orange
                  bands
              );
          } else if (modifiedY > 0.45) {
              // Cyan band
              bandColor = mix(
                  vec3(0.2, 0.8, 0.8),    // Deep cyan
                  vec3(0.6, 0.9, 0.9),    // Light cyan
                  bands
              );
          } else if (modifiedY > 0.35) {
              // Magenta-purple band
              bandColor = mix(
                  vec3(0.8, 0.2, 0.6),    // Deep magenta
                  vec3(1.0, 0.5, 0.8),    // Light pink
                  bands
              );
          } else if (modifiedY > 0.25) {
              // Default light band
              bandColor = mix(
                  vec3(0.47, 0.56, 0.77),   // #7990C6
                  vec3(0.035, 1.0, 0.482),    // #09FF7B
                  bands
              );
          } else {
              // Purple-tinted band
              bandColor = mix(
                  vec3(0.6, 0.2, 0.8),    // Deep purple
                  vec3(0.9, 0.7, 1.0),    // Light purple
                  bands
              );
          }
          
          // Add more dramatic tints
          bandColor *= mix(
              vec3(1.1, 0.95, 0.8),    // Golden tint
              vec3(1.0, 0.85, 0.95),   // Purple-ish tint
              sin(modifiedY * 12.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.1); // Less base color influence
          
          // Generate scattered spots
          for(int i = 0; i < 40; i++) {
              // Generate pseudo-random position based on index
              float randX = random(vec2(float(i) * 0.123, 0.42));
              float randY = random(vec2(float(i) * 0.567, 0.89));
              
              // Create spot position
              vec2 spotCenter = vec2(randX, randY);
              vec2 spotPos = vUv - spotCenter;
              
              // Randomize size (between 0.02 and 0.04)
              float size = 0.02 + 0.02 * random(vec2(float(i) * 0.789, 0.32));
              
              // Create spot
              float spotOuter = step(length(spotPos * vec2(2, 1.5)), size);
              float spotInner = step(length(spotPos * vec2(2, 1.5)), size * 0.7);
              
              // Random color selection based on spot index
              float colorChoice = random(vec2(float(i) * 0.345, 0.678));
              vec3 outerColor, innerColor;
              
              if (colorChoice < 0.33) {
                  outerColor = vec3(0.075, 0.518, 0.851);    // #1384D9 (blue)
                  innerColor = vec3(0.086, 0.612, 1.0);      // #169CFF
              } else if (colorChoice < 0.66) {
                  outerColor = vec3(0.769, 0.459, 0.039);    // #C46A0A (orange)
                  innerColor = vec3(0.851, 0.459, 0.043);    // #D9750B
              } else {
                  outerColor = vec3(0.510, 0.341, 0.800);    // #8257CC (purple)
                  innerColor = vec3(0.635, 0.427, 1.0);      // #A26DFF
              }
              
              finalColor = mix(finalColor, outerColor, spotOuter * 0.8);
              finalColor = mix(finalColor, innerColor, spotInner * 0.8);
          }
          
          // Main red spot (moved after the other spots)
          vec2 spotPos = vUv - vec2(.1, 0.4);
          float spotOuter = step(length(spotPos * vec2(2, 1.5)), 0.11);
          float spotInner = step(length(spotPos * vec2(2, 1.5)), 0.08);
          finalColor = mix(finalColor, vec3(0.7, 0.15, 0.08), spotOuter);
          finalColor = mix(finalColor, vec3(0.9, 0.2, 0.1), spotInner);
          
          // Calculate world position and direction to sun (0,0,0)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          // Calculate dot product between normal and direction to sun
          float dotProduct = dot(worldNormal, toSun);
          
          // Add shading spots on dark side
          if (dotProduct < 0.9) {
              float angle = acos(-dotProduct) * 2.0;
              
              // Base color
              vec4 currentColor = vec4(finalColor, 1.0);
              
              // spot (outer ring)
              if (angle < 3.3) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              
              // spot (middle ring)
              if (angle < 3.0) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              
              finalColor = currentColor.rgb;
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <group>
      <mesh renderOrder={2}>
        <sphereGeometry args={[69911, 64, 64]} />
        <shaderMaterial 
          attach="material"
          {...jupiterShader}
          side={THREE.FrontSide}
        />
      </mesh>

      {/* Outer atmosphere layer */}
      <mesh renderOrder={1}>
        <sphereGeometry args={[75000, 128, 640]} />
        <meshBasicMaterial 
          color="#f4c542"
          transparent={true}
          opacity={0.1}
          side={THREE.BackSide}
          depthWrite={true}
          depthTest={true}
        />
      </mesh>

      {/* Inner atmosphere layer */}
      <mesh renderOrder={-1}>
        <sphereGeometry args={[71000, 128, 640]} />
        <meshBasicMaterial 
          color="#f4c542"
          transparent={true}
          opacity={0.1}
          side={THREE.BackSide}
          depthWrite={true}
          depthTest={true}
        />
      </mesh>
    </group>
  );
};

export default Jupiter;
