import React, { useMemo } from 'react';
import * as THREE from 'three';
import { Text } from '@react-three/drei';

const Earth = React.forwardRef((props, ref) => {
  // Remove texture loader
  const worldTexture = useMemo(() => {
    return null;
  }, []);

  // Define land grid cells (renamed from australiaCells)
  const landCells = useMemo(() => new Set([
    'a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8', 'a9', 'a10', 'a11', 'a12', 'a13', 'a14', 'a15', 'a16', 'a17', 'a18', 'a19', 'a20', 'a21', 'a22', 'a23', 'a24', 'a25', 'a26', 'a27', 'a28', 'a29', 'a30', 'a31', 'a32', 'a33', 'a34', 'a35', 'a36', 'a37', 'a38', 'a39', 'a40', 'a41', 'a42', 'a43', 'a44', 'a45', 'a46', 'a47', 'a48', 'a49', 'a50', 'a51', 'a52', 'a53', 'a54', 'a55', 'a56', 'a57', 'a58', 'a59', 'a60',
    'b1', 'b2', 'b3', 'b4', 'b5', 'b6', 'b7', 'b8', 'b9', 'b10', 'b11', 'b12', 'b13', 'b14', 'b15', 'b16', 'b17', 'b18', 'b19', 'b20', 'b21', 'b22', 'b23', 'b24', 'b25', 'b26', 'b27', 'b28', 'b29', 'b30', 'b31', 'b32', 'b33', 'b34', 'b35', 'b36', 'b37', 'b38', 'b39', 'b40', 'b41', 'b42', 'b43', 'b44', 'b45', 'b46', 'b47', 'b48', 'b49', 'b50', 'b51', 'b52', 'b53', 'b54', 'b55', 'b56', 'b57', 'b58', 'b59', 'b60',
    'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'c10', 'c11', 'c12', 'c13', 'c14', 'c15', 'c16', 'c17', 'c18', 'c21', 'c22', 'c23', 'c24', 'c25', 'c26', 'c27', 'c30', 'c31', 'c32', 'c33', 'c34', 'c35', 'c36', 'c37', 'c38', 'c39', 'c40', 'c41', 'c42', 'c43', 'c44', 'c45', 'c46', 'c47', 'c48', 'c49', 'c50', 'c51', 'c52', 'c53', 'c54', 'c55', 'c56', 'c57', 'c58', 
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'o53', 'o54', 'o55', 'o51', 'o49', 'o48','o33', 'o34', 'o35', 'o36', 'o37',  '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'o17', 'o18', 'o19', 'o20', 'o21', 'o22', 'o23', 'o24', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'n33', 'n34', 'n35', 'n36', 'n37', 'n18', 'n20', 'n21', 'n22', 'n23', 'n24', 'n19', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'm52', 'm53', 'm55', 'm33', 'm34', 'm35', 'm36', 'm37', 'm39', 'm18', 'm19', 'm20', 'm21', 'm22', 'm23', 'm2', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'l50', 'l51', 'l52', 'l53', 'l54', 'l55','l33', 'l34', 'l35', 'l36', 'l38', 'l19', 'l20', 'l21', 'l22', 'l23', 'l2', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'k50', 'k51', 'k52', 'k53', 'k54', 'k55', 'k56','k34', 'k35', 'k19', 'k20', 'k21', 'k22', 'k1', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'j50', 'j51', 'j53', 'j54', 'j55', 'j34', 'j35', 'j19', 'j20', 'j21', 'j1', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'i19', 'i20', 'i60', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'h19', 'h59', 'h55', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'g19', 'g18', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'p33', 'p34', 'p35', 'p36', 'p37', 'p38', 'p48', 'p50', 'p32', 'p18', 'p19', 'p20', 'p21', 'p22', '', '', '', '', '',
    'q29', 'q30', 'q34', 'q35', 'q36', 'q37', 'q38', 'q39', 'q31', 'q32', 'q33', 'q43', 'q44','q47', 'q17', 'q18', 'q19', 'q20', 'q51', 'q54', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'r28', 'r29', 'r30', 'r31', 'r32', 'r33', 'r34', 'r35', 'r36', 'r37', 'r43', 'r44', 'r47','r48', 'r16', 'r15', 'r14', 'r51', 'r55', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    's28', 's29', 's30', 's31', 's32', 's33', 's34', 's35', 's36', 's38', 's39', 's40', 's43','s45', 's46', 's47', 's48', 's49', 's44', 's16', 's14', 's13', 's18', 's19', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    't29', 't30', 't31', 't32', 't33', 't34', 't35', 't36', 't38', 't41', 't42', 't43', 't44','t45', 't46', 't47', 't48', 't49', 't50', 't12', 't13', 't14', 't17', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'u30', 'u31', 'u32', 'u37', 'u38', 'u39', 'u40', 'u41', 'u42', 'u43', 'u44', 'u45', 'u46','u47', 'u48', 'u49', 'u50', 'u11', 'u12', 'u13', 'u14', 'u15', 'u16', 'u17', 'u53', 'u54', '', '', '', '', '', '', '', '', '', '', '', '', '',
    'v29', 'v35', 'v36', 'v37', 'v38', 'v34', 'v40', 'v41', 'v42', 'v43', 'v44', 'v45', 'v46','v47', 'v48', 'v49', 'v50', 'v52', 'v10', 'v11', 'v12', 'v13', 'v14', 'v15', 'v16', 'v17', 'v18', 'v54', '', '', '', '', '', '', '', '', '', '', '',
    'w31', 'w32', 'w33', 'w34', 'w30', 'w35', 'w37', 'w38', 'w40', 'w41', 'w42', 'w43', 'w44','w45', 'w46', 'w47', 'w48', 'w49', 'w50', 'w51', 'w52', 'w53', 'w29', 'w10', 'w11', 'w12', 'w13', 'w14', 'w15', 'w16', 'w17', 'w18', 'w19', 'w20', 'w54', '', '', '', '',
    'x31', 'x32', 'x33', 'x34', 'x35', 'x36', 'x37', 'x38', 'x39', 'x40', 'x41', 'x42', 'x43','x44', 'x45', 'x46', 'x47', 'x48', 'x49', 'x50', 'x51', 'x52', 'x53', 'x54', 'x9', 'x10', 'x11', 'x11', 'x12', 'x13', 'x14', 'x15', 'x16', 'x17', 'x18', 'x19', 'x20', 'x21', 'x57',
    'y30', 'y31', 'y35', 'y36', 'y37', 'y38', 'y38', 'y39', 'y40', 'y41', 'y42', 'y43', 'y44', 'y45', 'y46','y47', 'y48', 'y49', 'y50', 'y51', 'y52', 'y53', 'y57', 'y8', 'y9', 'y10', 'y11', 'y12', 'y13', 'y14', 'y15', 'y16', 'y18', 'y19', 'y20', 'y4', 'y5', '', '', '',
    'z32', 'z33', 'z35', 'z36', 'z37', 'z38', 'z39', 'z40', 'z41', 'z42', 'z43', 'z44', 'z45','z46', 'z47', 'z48', 'z49', 'z50', 'z51', 'z52', 'z53', 'z54', 'z55', 'z56', 'z57', 'z58', 'z59', 'z60', 'z23', 'z27', 'z28', 'z22', 'z17', 'z15', 'z14', 'z13', 'z12', 'z11', 'z10', 'z9', 'z8', 'z7', 'z6', 'z5', 'z4', 'z3', '', '', '', '', '', '',
    '[33', '[34', '[35', '[36', '[39', '[40', '[41', '[42', '[43', '[44', '[45', '[46', '[47', '[48', '[49', '[50', '[51', '[52', '[53', '[54', '[55', '[56', '[57', '[58', '[59', '[60', '[1', '[3', '[4', '[5', '[6', '[7', '[8', '[9', '[10', '[11', '[12', '[13', '[14', '[15', '[16', '[18', '[19', '[22', '[23', '[24', '[25', '[26', '[27', '[20',
    "$21", '$22', '$23', '$24', '$25', '$26', '$13', '$12', '$10', '$45', '$46', '$47', '$48', '$49', '$40', '$41', '', '', '', '', '', '',
    ']15', ']16', ']17', ']18', ']19', ']20', ']21', ']22', ']23', ']24', ']25', ']26', ']27', ']33', ']34',
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',// Ensure all labels are lowercase
    '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',

  ].filter(label => label !== '').map(label => label.toLowerCase())), []);

  // Generate grid cells
  const gridCells = useMemo(() => {
    const cells = [];
    const cellSize = 6.0;
    const radius = 6371;

    for (let lat = -90 + cellSize / 2; lat < 90; lat += cellSize) {
      for (let lon = -180 + cellSize / 2; lon < 180; lon += cellSize) {
        const phi = THREE.MathUtils.degToRad(90 - lat);
        const theta = THREE.MathUtils.degToRad(lon + 180);

        const x = -radius * Math.sin(phi) * Math.cos(theta);
        const y = radius * Math.cos(phi);
        const z = radius * Math.sin(phi) * Math.sin(theta);

        let rowLabel = String.fromCharCode(65 + Math.floor((lat + 90) / cellSize));
        if (rowLabel === '\\') {
          rowLabel = '$';
        }
        const colNum = Math.floor((lon + 180) / cellSize) + 1;
        const label = `${rowLabel}${colNum}`;

        cells.push({ 
          position: [x, y, z],
          label,
          normal: new THREE.Vector3(x, y, z).normalize(),
          isLand: landCells.has(label.toLowerCase())
        });
      }
    }
    return cells;
  }, [landCells]);

  // Create land DataTexture (renamed from australiaTexture)
  const landTexture = useMemo(() => {
    const width = 60;
    const height = 30;
    const data = new Uint8Array(width * height);

    gridCells.forEach((cell, index) => {
      data[index] = cell.isLand ? 255 : 0;
    });

    const texture = new THREE.DataTexture(data, width, height, THREE.RedFormat);
    texture.needsUpdate = true;
    texture.minFilter = THREE.NearestFilter;
    texture.magFilter = THREE.NearestFilter;
    texture.wrapS = THREE.ClampToEdgeWrapping;
    texture.wrapT = THREE.ClampToEdgeWrapping;
    return texture;
  }, [gridCells]);

  return (
    <group ref={ref}>
      {/* Add sky sphere */}
      <mesh renderOrder={2}>
        <sphereGeometry args={[6422.8, 220, 220]} />
        <shaderMaterial
          uniforms={{
            modelMatrix: { value: new THREE.Matrix4() },
          }}
          fragmentShader={`
            varying vec3 vPosition;
            varying vec3 vNormal;
            uniform mat4 modelMatrix;

            float easeInOutPower(float x) {
              return x < 0.5 ? 4.0 * x * x * x : 1.0 - pow(-2.0 * x + 2.0, 3.0) / 2.0;
            }

            void main() {
              vec4 skyColor = vec4(0.53, 0.81, 0.92, 0.0);  // Fully transparent base
              vec4 finalColor = skyColor;

              // Calculate sky circles
              vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
              vec3 toSun = normalize(-worldPos);
              vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
              
              float dotProduct = dot(worldNormal, toSun);
              
              if (dotProduct > -0.9) {
                float angle = acos(dotProduct) * 2.0;
                
                if (angle < 3.0) {
                  finalColor = vec4(0.25, 0.61, 0.85, 0.5);  // Solid color
                } else if (angle < 3.9) {
                  // Create smooth gradient from 3.0 to 3.6
                  float gradient = smoothstep(3.9, 3.0, angle);
                  gradient = easeInOutPower(gradient);
                  finalColor = mix(skyColor, vec4(0.25, 0.61, 0.85, 0.5), gradient);
                }
              }
              
              gl_FragColor = finalColor;
            }
          `}
          vertexShader={`
            varying vec3 vPosition;
            varying vec3 vNormal;

            void main() {
              vPosition = position;
              vNormal = normal;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `}
          transparent={true}
          depthWrite={false}
          side={THREE.BackSide}
          blending={THREE.CustomBlending}
          blendEquation={THREE.AddEquation}
          blendSrc={THREE.SrcAlphaFactor}
          blendDst={THREE.OneMinusSrcAlphaFactor}
        />
      </mesh>

      <mesh renderOrder={3}>
        <sphereGeometry args={[6371, 220, 220]} />
        <shaderMaterial
          uniforms={{
            cellSize: { value: 6.0 },
            worldTexture: { value: worldTexture },
            australiaTexture: { value: landTexture },
            modelMatrix: { value: new THREE.Matrix4() },
          }}
          fragmentShader={`
            uniform float cellSize;
            uniform sampler2D worldTexture;
            uniform sampler2D australiaTexture;
            uniform mat4 modelMatrix;

            varying vec2 vUv;
            varying vec3 vPosition;
            varying vec3 vNormal;

            bool isLandCell(int row, int col) {
                float u = (float(col) + 0.5) / 60.0;
                float v = (float(row) + 0.5) / 30.0;
                float flag = texture2D(australiaTexture, vec2(u, v)).r;
                return flag > 0.5;
            }

            bool isWaterCell(int row, int col) {
                return !isLandCell(row, col);
            }

            bool shouldRoundCornerLand(int row, int col, vec2 cornerPos) {
                bool isTopLeft = cornerPos.x < 0.5 && cornerPos.y < 0.5;
                bool isTopRight = cornerPos.x >= 0.5 && cornerPos.y < 0.5;
                bool isBottomLeft = cornerPos.x < 0.5 && cornerPos.y >= 0.5;
                bool isBottomRight = cornerPos.x >= 0.5 && cornerPos.y >= 0.5;

                bool hasTop = row > 0 ? isLandCell(row - 1, col) : false;
                bool hasBottom = row < 29 ? isLandCell(row + 1, col) : false;
                // Handle wrapping for left/right edges
                bool hasLeft = col > 0 ? isLandCell(row, col - 1) : isLandCell(row, 59);
                bool hasRight = col < 59 ? isLandCell(row, col + 1) : isLandCell(row, 0);

                if (isTopLeft) return !(hasTop || hasLeft);
                if (isTopRight) return !(hasTop || hasRight);
                if (isBottomLeft) return !(hasBottom || hasLeft);
                if (isBottomRight) return !(hasBottom || hasRight);
                
                return false;
            }

            bool shouldRoundCornerWater(int row, int col, vec2 cornerPos) {
                bool isTopLeft = cornerPos.x < 0.5 && cornerPos.y < 0.5;
                bool isTopRight = cornerPos.x >= 0.5 && cornerPos.y < 0.5;
                bool isBottomLeft = cornerPos.x < 0.5 && cornerPos.y >= 0.5;
                bool isBottomRight = cornerPos.x >= 0.5 && cornerPos.y >= 0.5;

                // Handle wrapping for left edge (col 0 connects to col 59)
                bool hasLeft = col > 0 ? isWaterCell(row, col - 1) : isWaterCell(row, 59);
                // Handle wrapping for right edge (col 59 connects to col 0)
                bool hasRight = col < 59 ? isWaterCell(row, col + 1) : isWaterCell(row, 0);
                bool hasTop = row > 0 ? isWaterCell(row - 1, col) : false;
                bool hasBottom = row < 29 ? isWaterCell(row + 1, col) : false;
                
                // Handle wrapping for diagonal cells
                bool hasTopLeft = row > 0 ? 
                    (col > 0 ? isWaterCell(row - 1, col - 1) : isWaterCell(row - 1, 59)) : 
                    false;
                bool hasTopRight = row > 0 ? 
                    (col < 59 ? isWaterCell(row - 1, col + 1) : isWaterCell(row - 1, 0)) : 
                    false;
                bool hasBottomLeft = row < 29 ? 
                    (col > 0 ? isWaterCell(row + 1, col - 1) : isWaterCell(row + 1, 59)) : 
                    false;
                bool hasBottomRight = row < 29 ? 
                    (col < 59 ? isWaterCell(row + 1, col + 1) : isWaterCell(row + 1, 0)) : 
                    false;

                if (isTopLeft) return !(hasTop || hasLeft || hasTopLeft);
                if (isTopRight) return !(hasTop || hasRight || hasTopRight);
                if (isBottomLeft) return !(hasBottom || hasLeft || hasBottomLeft);
                if (isBottomRight) return !(hasBottom || hasRight || hasBottomRight);
                
                return false;
            }

            void main() {
                float lat = (vUv.y - 0.5) * 180.0;
                float lon = (vUv.x - 0.5) * 360.0;
                
                vec4 baseColor = vec4(0.2, 0.4, 0.7, 1.0);     // Medium-dark green
                vec4 oceanGridColor = vec4(0.2, 0.4, 0.7, 1.0);    // Darker blue
                vec4 landColor = vec4(0.09, 0.52, 0.17, 1.0);      // Medium-dark green
                vec4 darkLandColor = vec4(0.07, 0.42, 0.14, 1.0);  // Darker green for offset grid
                vec4 antarcticaColor = vec4(1.0, 1.0, 1.0, 1.0);

                // Corner colors
                vec4 topLeftColor = vec4(0.09, 0.52, 0.17, 1.0);      // Red
                vec4 topRightColor = vec4(0.07, 0.42, 0.14, 1.0);    // Orange
                vec4 bottomLeftColor = vec4(0.07, 0.42, 0.14, 1.0);   // Yellow
                vec4 bottomRightColor = vec4(0.07, 0.42, 0.14, 1.0);  // Purple

                // Original grid
                float rowFloat = floor((lat + 90.0) / cellSize);
                float colFloat = floor((lon + 180.0) / cellSize);
                int row = int(rowFloat);
                int col = int(colFloat);
                
                vec2 cellPos = vec2(
                    fract((lon + 180.0) / cellSize),
                    fract((lat + 90.0) / cellSize)
                );

                // Offset grid (shifted by quarter cell instead of half)
                float offsetLat = lat + cellSize * 0.1;
                float offsetLon = lon + cellSize * 0.1;
                float offsetRowFloat = floor((offsetLat + 90.0) / cellSize);
                float offsetColFloat = floor((offsetLon + 180.0) / cellSize);
                int offsetRow = int(offsetRowFloat);
                int offsetCol = int(offsetColFloat);

                bool isLand = isLandCell(row, col);
                bool isOffsetLand = isLandCell(offsetRow, offsetCol);
                bool isAntarctica = row >= 0 && row <= 3;
                bool isOffsetAntarctica = offsetRow >= 0 && offsetRow <= 3;

                vec4 finalColor;
                float cornerSize = 0.25;

                // Render both grids
                if (isLand) {
                    if (isAntarctica) {
                        if (shouldRoundCornerLand(row, col, cellPos)) {
                            vec2 cornerPoint = vec2(
                                cellPos.x < 0.5 ? 0.0 : 1.0,
                                cellPos.y < 0.5 ? 0.0 : 1.0
                            );
                            vec2 distToCorner = abs(cellPos - cornerPoint);
                            float cornerDist = distToCorner.x + distToCorner.y;
                            
                            if (cornerDist < cornerSize) {
                                // For bottom left corner only
                                if (cellPos.x < 0.5 && cellPos.y < 0.5) {
                                    finalColor = darkLandColor;
                                } else {
                                    finalColor = oceanGridColor;
                                }
                            } else {
                                finalColor = antarcticaColor;
                            }
                        } else {
                            finalColor = antarcticaColor;
                        }
                    } else {
                        if (shouldRoundCornerLand(row, col, cellPos)) {
                            vec2 cornerPoint = vec2(
                                cellPos.x < 0.5 ? 0.0 : 1.0,
                                cellPos.y < 0.5 ? 0.0 : 1.0
                            );
                            vec2 distToCorner = abs(cellPos - cornerPoint);
                            float cornerDist = distToCorner.x + distToCorner.y;
                            
                            if (cornerDist < cornerSize) {
                                // For bottom left corner only
                                if (cellPos.x < 0.5 && cellPos.y < 0.5) {
                                    finalColor = darkLandColor;
                                } else {
                                    finalColor = oceanGridColor;
                                }
                            } else {
                                finalColor = landColor;
                            }
                        } else {
                            finalColor = landColor;
                        }
                    }
                } else if (isOffsetLand && !isOffsetAntarctica) {
                    vec2 offsetCellPos = vec2(
                        fract((offsetLon + 180.0) / cellSize),
                        fract((offsetLat + 90.0) / cellSize)
                    );
                    
                    if (shouldRoundCornerLand(offsetRow, offsetCol, offsetCellPos)) {
                        vec2 cornerPoint = vec2(
                            offsetCellPos.x < 0.5 ? 0.0 : 1.0,
                            offsetCellPos.y < 0.5 ? 0.0 : 1.0
                        );
                        vec2 distToCorner = abs(offsetCellPos - cornerPoint);
                        float cornerDist = distToCorner.x + distToCorner.y;
                        
                        if (cornerDist < cornerSize) {
                            finalColor = oceanGridColor;
                        } else {
                            finalColor = darkLandColor;
                        }
                    } else {
                        finalColor = darkLandColor;
                    }
                } else {
                    if (shouldRoundCornerWater(row, col, cellPos)) {
                        vec2 cornerPoint = vec2(
                            cellPos.x < 0.5 ? 0.0 : 1.0,
                            cellPos.y < 0.5 ? 0.0 : 1.0
                        );
                        vec2 distToCorner = abs(cellPos - cornerPoint);
                        float cornerDist = distToCorner.x + distToCorner.y;
                        
                        if (cornerDist < cornerSize) {
                            // Determine which corner we're in and assign appropriate color
                            if (cellPos.x < 0.5 && cellPos.y < 0.5) {
                                finalColor = topLeftColor;
                            } else if (cellPos.x >= 0.5 && cellPos.y < 0.5) {
                                finalColor = topRightColor;
                            } else if (cellPos.x < 0.5 && cellPos.y >= 0.5) {
                                finalColor = bottomLeftColor;
                            } else {
                                finalColor = bottomRightColor;
                            }
                        } else {
                            finalColor = oceanGridColor;
                        }
                    } else {
                        finalColor = oceanGridColor;
                    }
                }

                // Add shadow circles
                vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
                vec3 toSun = normalize(-worldPos);
                vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
                
                float dotProduct = dot(worldNormal, toSun);
                
                if (dotProduct < 0.9) {
                    float angle = acos(-dotProduct) * 2.0;
                    
                    if (angle < 3.15) {
                        finalColor = mix(finalColor, vec4(0.0, 0.0, 0.0, 1.0), 0.05);
                    }
                }
                
                gl_FragColor = finalColor;
            }
          `}
          vertexShader={`
            varying vec2 vUv;
            varying vec3 vPosition;
            varying vec3 vNormal;

            void main() {
              vUv = uv;
              vPosition = position;
              vNormal = normal;
              gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
          `}
          transparent={false}
          depthWrite={true}
          depthTest={true}
          side={THREE.FrontSide}
        />
      </mesh>
    </group>
  );
});

export default Earth;