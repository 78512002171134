// Template data and configuration for SlideCreator

// Reusable Dial Component
export const Dial = ({ label, value, onChange, min, max, step }) => {
  return (
    <div className="flex items-center space-x-2">
      <span className="w-20 text-sm text-gray-700">{label}</span>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        step={step}
        className="flex-1"
      />
      <span className="w-10 text-sm text-gray-700">{value.toFixed(2)}</span>
    </div>
  );
};

// Slider component
export const Slider = ({ label, value, onChange, min = 0, max = 10, step = 0.5 }) => (
  <div className="flex items-center mb-1">
    <input
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
      className="w-24"
    />
    <span className="ml-1 text-[10px]">{label}: {value}</span>
  </div>
);

// Slide templates data
export const slideTemplates = {
  Space: [
    { id: 'space-sun-static', name: 'Sun Static', color: '#FFA500', preview: '☀️', theme: 'sun-static' },
    { id: 'space-earth-orbit', name: 'Earth Orbit', color: '#1E293B', preview: '🌍', theme: 'earth-orbit' },
    { id: 'space-earth-static', name: 'Earth Static', color: '#1E293B', preview: '🌍', theme: 'earth-static' },
    { id: 'space-earth-spinning', name: 'Earth Spinning', color: '#1E293B', preview: '🌍', theme: 'earth-spinning' },
    { id: 'space-moon-orbit', name: 'Moon Orbit', color: '#312E81', preview: '🌜', theme: 'moon-orbit' },
    { id: 'space-moon-static', name: 'Moon Static', color: '#312E81', preview: '🌜', theme: 'moon-static' },
    { id: 'space-mercury-orbit', name: 'Mercury Orbit', color: '#1E293B', preview: '🟤', theme: 'mercury-orbit' },
    { id: 'space-mercury-static', name: 'Mercury Static', color: '#1E293B', preview: '🟤', theme: 'mercury-static' },
    { id: 'space-venus-orbit', name: 'Venus Orbit', color: '#312E81', preview: '🌖', theme: 'venus-orbit' },
    { id: 'space-venus-static', name: 'Venus Static', color: '#312E81', preview: '🌖', theme: 'venus-static' },
    { id: 'space-mars-orbit', name: 'Mars Orbit', color: '#1E293B', preview: '🔴', theme: 'mars-orbit' },
    { id: 'space-mars-static', name: 'Mars Static', color: '#1E293B', preview: '🔴', theme: 'mars-static' },
    { id: 'space-jupiter-orbit', name: 'Jupiter Orbit', color: '#312E81', preview: '🟢', theme: 'jupiter-orbit' },
    { id: 'space-jupiter-static', name: 'Jupiter Static', color: '#312E81', preview: '🟢', theme: 'jupiter-static' },
    { id: 'space-saturn-orbit', name: 'Saturn Orbit', color: '#1E293B', preview: '🪐', theme: 'saturn-orbit' },
    { id: 'space-saturn-static', name: 'Saturn Static', color: '#1E293B', preview: '🪐', theme: 'saturn-static' },
    { id: 'space-uranus-orbit', name: 'Uranus Orbit', color: '#312E81', preview: '🔵', theme: 'uranus-orbit' },
    { id: 'space-uranus-static', name: 'Uranus Static', color: '#312E81', preview: '🔵', theme: 'uranus-static' },
    { id: 'space-neptune-orbit', name: 'Neptune Orbit', color: '#1E293B', preview: '🟠', theme: 'neptune-orbit' },
    { id: 'space-neptune-static', name: 'Neptune Static', color: '#1E293B', preview: '🟠', theme: 'neptune-static' },
    { id: 'space-size', name: 'Solar System - Size Scale', color: '#312E81', preview: '📏', theme: 'size-scale' },
    { id: 'space-distance', name: 'Solar System - Distance Scale', color: '#1E293B', preview: '📐', theme: 'distance-scale' }
  ],
  Continents: [
    { id: 'continent-na', name: 'North America', color: '#2563EB', preview: '🌎', theme: 'north-america' },
    { id: 'continent-sa', name: 'South America', color: '#059669', preview: '🌎', theme: 'south-america' },
    { id: 'continent-eu', name: 'Europe', color: '#7C3AED', preview: '🌍', theme: 'europe' },
    { id: 'continent-af', name: 'Africa', color: '#B45309', preview: '🌍', theme: 'africa' },
    { id: 'continent-as', name: 'Asia', color: '#DC2626', preview: '🌏', theme: 'asia' },
    { id: 'continent-oc', name: 'Oceania', color: '#0EA5E9', preview: '🌏', theme: 'oceania' },
    { id: 'continent-an', name: 'Antarctica', color: '#E5E7EB', preview: '🗺️', theme: 'antarctica' }
  ],
  Countries: [
    { id: 'country-us', name: 'United States', color: '#1E40AF', preview: '🇺🇸', theme: 'usa' },
    { id: 'country-cn', name: 'China', color: '#DC2626', preview: '🇨🇳', theme: 'china' },
    { id: 'country-in', name: 'India', color: '#15803D', preview: '🇮🇳', theme: 'india' },
    { id: 'country-ru', name: 'Russia', color: '#1E3A8A', preview: '🇷🇺', theme: 'russia' },
    { id: 'country-br', name: 'Brazil', color: '#15803D', preview: '🇧🇷', theme: 'brazil' },
    { id: 'country-uk', name: 'United Kingdom', color: '#1E40AF', preview: '🇬🇧', theme: 'uk' },
    { id: 'country-fr', name: 'France', color: '#1E40AF', preview: '🇫🇷', theme: 'france' },
    { id: 'country-de', name: 'Germany', color: '#DC2626', preview: '🇩🇪', theme: 'germany' },
    { id: 'country-jp', name: 'Japan', color: '#DC2626', preview: '🇯🇵', theme: 'japan' },
    { id: 'country-it', name: 'Italy', color: '#15803D', preview: '🇮🇹', theme: 'italy' },
    { id: 'country-ca', name: 'Canada', color: '#DC2626', preview: '🇨🇦', theme: 'canada' },
    { id: 'country-au', name: 'Australia', color: '#1E40AF', preview: '🇦🇺', theme: 'australia' },
    { id: 'country-kr', name: 'South Korea', color: '#1E40AF', preview: '🇰🇷', theme: 'south-korea' },
    { id: 'country-es', name: 'Spain', color: '#DC2626', preview: '🇪🇸', theme: 'spain' },
    { id: 'country-mx', name: 'Mexico', color: '#15803D', preview: '🇲🇽', theme: 'mexico' },
    { id: 'country-id', name: 'Indonesia', color: '#DC2626', preview: '🇮🇩', theme: 'indonesia' },
    { id: 'country-sa', name: 'Saudi Arabia', color: '#15803D', preview: '🇸🇦', theme: 'saudi-arabia' },
    { id: 'country-tr', name: 'Turkey', color: '#DC2626', preview: '🇹🇷', theme: 'turkey' },
    { id: 'country-za', name: 'South Africa', color: '#15803D', preview: '🇿🇦', theme: 'south-africa' },
    { id: 'country-ar', name: 'Argentina', color: '#1E40AF', preview: '🇦🇷', theme: 'argentina' },
    { id: 'country-eg', name: 'Egypt', color: '#DC2626', preview: '🇪🇬', theme: 'egypt' },
    { id: 'country-nl', name: 'Netherlands', color: '#1E40AF', preview: '🇳🇱', theme: 'netherlands' },
    { id: 'country-ch', name: 'Switzerland', color: '#DC2626', preview: '🇨🇭', theme: 'switzerland' },
    { id: 'country-se', name: 'Sweden', color: '#1E40AF', preview: '🇸🇪', theme: 'sweden' },
    { id: 'country-pl', name: 'Poland', color: '#DC2626', preview: '🇵🇱', theme: 'poland' },
    { id: 'country-be', name: 'Belgium', color: '#1E40AF', preview: '🇧🇪', theme: 'belgium' },
    { id: 'country-no', name: 'Norway', color: '#DC2626', preview: '🇳🇴', theme: 'norway' },
    { id: 'country-at', name: 'Austria', color: '#DC2626', preview: '🇦🇹', theme: 'austria' },
    { id: 'country-ie', name: 'Ireland', color: '#15803D', preview: '🇮🇪', theme: 'ireland' },
    { id: 'country-gr', name: 'Greece', color: '#1E40AF', preview: '🇬🇷', theme: 'greece' },
    { id: 'country-pt', name: 'Portugal', color: '#15803D', preview: '🇵🇹', theme: 'portugal' },
    { id: 'country-nz', name: 'New Zealand', color: '#1E40AF', preview: '🇳🇿', theme: 'new-zealand' },
    { id: 'country-sg', name: 'Singapore', color: '#DC2626', preview: '🇸🇬', theme: 'singapore' },
    { id: 'country-dk', name: 'Denmark', color: '#DC2626', preview: '🇩🇰', theme: 'denmark' },
    { id: 'country-fi', name: 'Finland', color: '#1E40AF', preview: '🇫🇮', theme: 'finland' }
  ],
  Cities: [
    { id: 'city-nyc', name: 'New York City', color: '#2563EB', preview: '🗽', theme: 'nyc' },
    { id: 'city-london', name: 'London', color: '#7C3AED', preview: '🇬🇧', theme: 'london' },
    { id: 'city-paris', name: 'Paris', color: '#059669', preview: '🗼', theme: 'paris' },
    { id: 'city-tokyo', name: 'Tokyo', color: '#DC2626', preview: '🗼', theme: 'tokyo' },
    { id: 'city-beijing', name: 'Beijing', color: '#B45309', preview: '🏛️', theme: 'beijing' },
    { id: 'city-dubai', name: 'Dubai', color: '#F59E0B', preview: '🌇', theme: 'dubai' },
    { id: 'city-moscow', name: 'Moscow', color: '#1E40AF', preview: '⛪', theme: 'moscow' },
    { id: 'city-rio', name: 'Rio de Janeiro', color: '#15803D', preview: '⛰️', theme: 'rio' },
    { id: 'city-sydney', name: 'Sydney', color: '#0EA5E9', preview: '🏛️', theme: 'sydney' },
    { id: 'city-cairo', name: 'Cairo', color: '#B45309', preview: '🏛️', theme: 'cairo' }
  ],
  General: [
    { id: 'general-conversation', name: 'Conversation', color: '#3B82F6', preview: '💬', theme: 'conversation' },
    { id: 'general-thinking', name: 'Thinking', color: '#8B5CF6', preview: '🤔', theme: 'thinking' },
    { id: 'general-group', name: 'Group', color: '#10B981', preview: '👥', theme: 'group' },
    { id: 'general-image', name: 'Image', color: '#F59E0B', preview: '🖼️', theme: 'image' },
    { id: 'general-video', name: 'Video', color: '#EF4444', preview: '🎥', theme: 'video' }
  ],
  Classroom: [
    { id: 'classroom-blue', name: 'Blue Board', color: '#3B82F6', preview: '📘', theme: 'blue' },
    { id: 'classroom-white', name: 'White Board', color: '#FFFFFF', preview: '📝', theme: 'white' },
    { id: 'classroom-green', name: 'Green Board', color: '#10B981', preview: '📗', theme: 'green' }
  ],
  Office: [
    { id: 'office-modern', name: 'Modern Office', color: '#6B7280', preview: '💼', theme: 'gray' },
    { id: 'office-meeting', name: 'Meeting Room', color: '#1F2937', preview: '🤝', theme: 'dark' }
  ],
  Desk: [
    { id: 'desk-study', name: 'Study Desk', color: '#6B7280', preview: '📚', theme: 'study' },
    { id: 'desk-computer', name: 'Computer Desk', color: '#374151', preview: '💻', theme: 'computer' }
  ],
  Body: [
    { id: 'body-anatomy', name: 'Anatomy', color: '#EF4444', preview: '🫀', theme: 'anatomy' },
    { id: 'body-cells', name: 'Cells', color: '#DC2626', preview: '🦠', theme: 'cells' }
  ],
  Shop: [
    { id: 'shop-retail', name: 'Retail Store', color: '#F59E0B', preview: '🛍️', theme: 'retail' },
    { id: 'shop-market', name: 'Marketplace', color: '#D97706', preview: '🏪', theme: 'market' }
  ],
  Hospital: [
    { id: 'hospital-ward', name: 'Hospital Ward', color: '#10B981', preview: '🏥', theme: 'ward' },
    { id: 'hospital-lab', name: 'Medical Lab', color: '#059669', preview: '🔬', theme: 'lab' }
  ],
  Movies: [
    { id: 'movies-cinema', name: 'Cinema Hall', color: '#6D28D9', preview: '🎬', theme: 'cinema' },
    { id: 'movies-studio', name: 'Film Studio', color: '#5B21B6', preview: '🎥', theme: 'studio' }
  ],
  Park: [
    { id: 'park-playground', name: 'Playground', color: '#34D399', preview: '🎡', theme: 'playground' },
    { id: 'park-garden', name: 'Garden', color: '#059669', preview: '🌺', theme: 'garden' }
  ],

  Home: [
    { id: 'home-living', name: 'Living Room', color: '#F59E0B', preview: '🏠', theme: 'warm' },
    { id: 'home-kitchen', name: 'Kitchen', color: '#D97706', preview: '🍳', theme: 'orange' }
  ],
  Street: [
    { id: 'street-city', name: 'City Street', color: '#4B5563', preview: '🌆', theme: 'urban' },
    { id: 'street-park', name: 'Park', color: '#34D399', preview: '🌳', theme: 'nature' }
  ],
  Beach: [
    { id: 'beach-sunny', name: 'Sunny Beach', color: '#FBBF24', preview: '🏖️', theme: 'sunny' },
    { id: 'beach-sunset', name: 'Sunset Beach', color: '#F97316', preview: '🌅', theme: 'sunset' }
  ],
  Sea: [
    { id: 'sea-surface', name: 'Sea Surface', color: '#38BDF8', preview: '🌊', theme: 'ocean' },
    { id: 'sea-underwater', name: 'Underwater', color: '#0EA5E9', preview: '🐠', theme: 'deep' }
  ],
  Landscape: [
    { id: 'landscape-mountain', name: 'Mountain', color: '#78716C', preview: '🏔️', theme: 'mountain' },
    { id: 'landscape-forest', name: 'Forest', color: '#166534', preview: '🌲', theme: 'forest' }
  ],
  Data: [
    { id: 'data-charts', name: 'Charts', color: '#4F46E5', preview: '📊', theme: 'charts' },
    { id: 'data-graphs', name: 'Graphs', color: '#7C3AED', preview: '📈', theme: 'graphs' },
    { id: 'data-bigger-than', name: 'Number: Bigger Than', color: '#4F46E5', preview: '>', theme: 'bigger-than' },
    { id: 'data-more-than', name: 'Number: More Than', color: '#7C3AED', preview: '>', theme: 'more-than' },
    { id: 'data-smaller-than', name: 'Number: Smaller Than', color: '#4F46E5', preview: '<', theme: 'smaller-than' },
    { id: 'data-less-than', name: 'Number: Less Than', color: '#7C3AED', preview: '<', theme: 'less-than' },
    { id: 'data-rarity', name: 'Number: Rarity', color: '#4F46E5', preview: '🎲', theme: 'rarity' }
  ],
  Interactive: [
    { id: 'interactive-quiz', name: 'Quiz', color: '#EC4899', preview: '❓', theme: 'quiz' },
    { id: 'interactive-draw', name: 'Draw', color: '#8B5CF6', preview: '✏️', theme: 'draw' },
    { id: 'interactive-write', name: 'Write', color: '#6366F1', preview: '✍️', theme: 'write' },
    { id: 'interactive-poll', name: 'Poll', color: '#14B8A6', preview: '📊', theme: 'poll' }
  ],
  'Time/Date': [
    { id: 'time-set', name: 'Set Time', color: '#4B5563', preview: '⏰', theme: 'time' }
  ],
  Custom: [
      { id: 'custom-blank', name: 'Blank Canvas', color: '#E5E7EB', preview: '✏️', theme: 'blank' },
      { id: 'custom-template', name: 'Custom Template', color: '#9CA3AF', preview: '🎨', theme: 'custom' }
    ],
};

// Location coordinates data
export const locationCoordinates = {
  Custom: { x: 0, y: 150, z: -1000 },
  Space: { x: 0, y: 3500, z: -1000 },
  Atmosphere: { x: 0, y: 1400, z: -600 },
  Sky: { x: 0, y: 550, z: -1000 },
  OuterRange: { x: 0, y: 15, z: 600 },
  MidRange: { x: 0, y: 15, z: 200 },
  Near: { x: 90000, y: -91000, z: -500000 },
  Ground: { x: 0, y: 15, z: -300 },
  UnderGround: { x: 0, y: -500, z: -300 },
  SolarSystem: { x: 0, y: 0, z: 790000000 },
  Sun: { x: 90000000, y: 90000000, z: 790000000 },
  Mercury: { x: 60000000, y: 60000000, z: 590000000 },
  Venus: { x: 30000000, y: 30000000, z: 290000000 },
  Earth: { x: 90000, y: -91000, z: 0 },
  Mars: { x: -30000000, y: -30000000, z: -310000000 },
  Jupiter: { x: -60000000, y: -60000000, z: -610000000 },
  Saturn: { x: -90000000, y: -90000000, z: -910000000 },
  Uranus: { x: -120000000, y: -120000000, z: -1210000000 },
  Neptune: { x: -150000000, y: -150000000, z: -1510000000 }
};

// Planet labels data
export const planetLabels = [
  { name: 'Sun', position: { x: 90000000, y: 90000000, z: 790000000 }, color: '#FFD700' },
  { name: 'Mercury', position: { x: 60000000, y: 60000000, z: 590000000 } },
  { name: 'Venus', position: { x: 30000000, y: 30000000, z: 290000000 } },
  { name: 'Earth', position: { x: 90000, y: -91000, z: 0 } },
  { name: 'Mars', position: { x: -30000000, y: -30000000, z: -310000000 } },
  { name: 'Jupiter', position: { x: -60000000, y: -60000000, z: -610000000 } },
  { name: 'Saturn', position: { x: -90000000, y: -90000000, z: -910000000 } },
  { name: 'Uranus', position: { x: -120000000, y: -120000000, z: -1210000000 } },
  { name: 'Neptune', position: { x: -150000000, y: -150000000, z: -1510000000 } }
];

// Helper functions
export const hasMoons = (planet) => {
  const moonsMap = {
    Earth: ['Moon'],
    Mars: ['Phobos', 'Deimos'],
    Jupiter: ['Io', 'Europa', 'Ganymede', 'Callisto'],
    Saturn: ['Titan', 'Enceladus', 'Mimas', 'Rhea'],
    Uranus: ['Miranda', 'Ariel', 'Umbriel', 'Titania', 'Oberon'],
    Neptune: ['Triton', 'Nereid', 'Naiad']
  };
  return !!moonsMap[planet];
};

export const getMoonsForPlanet = (planet) => {
  const moonsMap = {
    Earth: ['Moon'],
    Mars: ['Phobos', 'Deimos'],
    Jupiter: ['Io', 'Europa', 'Ganymede', 'Callisto'],
    Saturn: ['Titan', 'Enceladus', 'Mimas', 'Rhea'],
    Uranus: ['Miranda', 'Ariel', 'Umbriel', 'Titania', 'Oberon'],
    Neptune: ['Triton', 'Nereid', 'Naiad']
  };
  return moonsMap[planet] || [];
};

// Initial data generators
export const generateInitialSlides = () => {
  return [{
    id: Date.now(),
    subtitle: "",
    x: 0,
    y: 0,
    z: 0,
    location: 'Near',
    theme: 'earth-orbit',
    color: '#1E293B',
    preview: '🌍',
    name: 'Earth Orbit'
  }];
};

export const generateInitialLocationTextMatrix = () => ({
  Space: ['', '', '', '', '', '', '', '', '', ''],
  Atmosphere: ['', '', '', '', '', '', '', '', '', ''],
  Sky: ['', '', '', '', '', '', '', '', '', ''],
  OuterRange: ['', '', '', '', '', '', '', '', '', ''],
  MidRange: ['', '', '', '', '', '', '', '', '', ''],
  Near: ['', '', '', '', '', '', '', '', '', ''],
  Ground: ['', '', '', '', '', '', '', '', '', ''],
  UnderGround: ['', '', '', '', '', '', '', '', '', ''],
  SolarSystem: ['', '', '', '', '', '', '', '', '', ''],
  Sun: ['', '', '', '', '', '', '', '', '', ''],
  Mercury: ['', '', '', '', '', '', '', '', '', ''],
  Venus: ['', '', '', '', '', '', '', '', '', ''],
  Earth: ['', '', '', '', '', '', '', '', '', ''],
  Mars: ['', '', '', '', '', '', '', '', '', ''],
  Jupiter: ['', '', '', '', '', '', '', '', '', ''],
  Saturn: ['', '', '', '', '', '', '', '', '', ''],
  Uranus: ['', '', '', '', '', '', '', '', '', ''],
  Neptune: ['', '', '', '', '', '', '', '', '', '']
});

// Placeholder subtitles
export const placeholderSubtitles = [
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
  "",
];

// Default genres
export const defaultGenres = ['Blank'];
// Default settings for camera/world props by slide theme.
// You can add more keys (such as guidanceSystem, viewLock, etc.) as needed.
export const slideSettings = {
  'sun-static': {
    selectedCelestialBody: 'Sun',
    timelapse: 0,
    showMoons: false,
    selectedMoon: ''
  },
  'earth-orbit': {
    selectedCelestialBody: 'Earth',
    timelapse: 7
  },
  'earth-static': {
    selectedCelestialBody: 'Earth',
    timelapse: 0
  },
  'earth-spinning': {
    selectedCelestialBody: 'Earth',
    timelapse: 7
  },
  'moon-orbit': {
    selectedCelestialBody: 'Moon',
    timelapse: 7,
    showMoons: true
  },
  'moon-static': {
    selectedCelestialBody: 'Moon',
    timelapse: 0,
    showMoons: true
  },
  'mercury-orbit': {
    selectedCelestialBody: 'Mercury',
    timelapse: 7
  },
  'mercury-static': {
    selectedCelestialBody: 'Mercury',
    timelapse: 0
  },
  'venus-orbit': {
    selectedCelestialBody: 'Venus',
    timelapse: 7
  },
  'venus-static': {
    selectedCelestialBody: 'Venus',
    timelapse: 0
  },
  'mars-orbit': {
    selectedCelestialBody: 'Mars',
    timelapse: 7
  },
  'mars-static': {
    selectedCelestialBody: 'Mars',
    timelapse: 0
  },
  'jupiter-orbit': {
    selectedCelestialBody: 'Jupiter',
    timelapse: 7
  },
  'jupiter-static': {
    selectedCelestialBody: 'Jupiter',
    timelapse: 0
  },
  'saturn-orbit': {
    selectedCelestialBody: 'Saturn',
    timelapse: 7
  },
  'saturn-static': {
    selectedCelestialBody: 'Saturn',
    timelapse: 0
  },
  'uranus-orbit': {
    selectedCelestialBody: 'Uranus',
    timelapse: 7
  },
  'uranus-static': {
    selectedCelestialBody: 'Uranus',
    timelapse: 0
  },
  'neptune-orbit': {
    selectedCelestialBody: 'Neptune',
    timelapse: 7
  },
  'neptune-static': {
    selectedCelestialBody: 'Neptune',
    timelapse: 0
  },
  'south-america': {
    selectedCelestialBody: 'Earth',
    zoom: 61,
    axisD: -17,
    timelapse: 7,
    axisZoom: 1.0
  },
  // Continents
  'north-america': {
    selectedCelestialBody: 'Earth',
    zoom: 91,
    axisD: 35,
    timelapse: 7,
    axisZoom: 1.0
  },
  'south-america': {
    selectedCelestialBody: 'Earth',
    zoom: 61,
    axisD: -17,
    timelapse: 7,
    axisZoom: 1.0
  },
  'europe': {
    selectedCelestialBody: 'Earth',
    zoom: 329,
    axisD: 58,
    timelapse: 7,
    axisZoom: 1.0
  },
  'africa': {
    selectedCelestialBody: 'Earth',
    zoom: 342,
    axisD: 9,
    timelapse: 7,
    axisZoom: 1.0
  },
  'asia': {
    selectedCelestialBody: 'Earth',
    zoom: 260,
    axisD: 24,
    timelapse: 7,
    axisZoom: 1.0
  },
  'oceania': {
    selectedCelestialBody: 'Earth',
    zoom: 222,
    axisD: -23,
    timelapse: 7,
    axisZoom: 1.0
  },
  'antarctica': {
    selectedCelestialBody: 'Earth',
    axisD: -89,
    timelapse: 7,
    axisZoom: 1.0
  },

  // Countries (first group)
  'usa': {
    selectedCelestialBody: 'Earth',
    zoom: 91,
    axisD: 35,
    timelapse: 7,
    axisZoom: 3.0
  },
  'china': {
    selectedCelestialBody: 'Earth',
    zoom: 260,
    axisD: 24,
    timelapse: 7,
    axisZoom: 3.0
  },
  'india': {
    selectedCelestialBody: 'Earth',
    zoom: 267,
    axisD: 24,
    timelapse: 7,
    axisZoom: 3.0
  },
  'russia': {
    selectedCelestialBody: 'Earth',
    zoom: 247,
    axisD: 40,
    timelapse: 7,
    axisZoom: 3.0
  },
  'brazil': {
    selectedCelestialBody: 'Earth',
    zoom: 57,
    axisD: -16,
    timelapse: 7,
    axisZoom: 3.0
  },
  'uk': {
    selectedCelestialBody: 'Earth',
    zoom: 360,
    axisD: 58,
    timelapse: 7,
    axisZoom: 3.0
  },
  'france': {
    selectedCelestialBody: 'Earth',
    zoom: 360,
    axisD: 44,
    timelapse: 7,
    axisZoom: 3.0
  },
  'germany': {
    selectedCelestialBody: 'Earth',
    zoom: 345,
    axisD: 58,
    timelapse: 7,
    axisZoom: 3.0
  },
  'japan': {
    selectedCelestialBody: 'Earth',
    zoom: 217,
    axisD: 40,
    timelapse: 7,
    axisZoom: 3.0
  },
  'italy': {
    selectedCelestialBody: 'Earth',
    zoom: 360,
    axisD: 37,
    timelapse: 7,
    axisZoom: 3.0
  },
  'canada': {
    selectedCelestialBody: 'Earth',
    zoom: 111,
    axisD: 56,
    timelapse: 7,
    axisZoom: 3.0
  },
  'australia': {
    selectedCelestialBody: 'Earth',
    zoom: 222,
    axisD: -23,
    timelapse: 7,
    axisZoom: 3.0
  },
  'south-korea': {
    selectedCelestialBody: 'Earth',
    zoom: 240,
    axisD: 42,
    timelapse: 7,
    axisZoom: 3.0
  },
  'spain': {
    selectedCelestialBody: 'Earth',
    zoom: 9,
    axisD: 45,
    timelapse: 7,
    axisZoom: 3.0
  },
  'mexico': {
    selectedCelestialBody: 'Earth',
    zoom: 92,
    axisD: 21,
    timelapse: 7,
    axisZoom: 3.0
  },

  // Additional Countries
  'indonesia': {
    selectedCelestialBody: 'Earth',
    zoom: 222,
    axisD: 9,
    timelapse: 7,
    axisZoom: 3.0
  },
  'saudi-arabia': {
    selectedCelestialBody: 'Earth',
    zoom: 310,
    axisD: 27,
    timelapse: 7,
    axisZoom: 3.0
  },
  'turkey': {
    selectedCelestialBody: 'Earth',
    zoom: 310,
    axisD: 31,
    timelapse: 7,
    axisZoom: 3.0
  },
  'south-africa': {
    selectedCelestialBody: 'Earth',
    zoom: 338,
    axisD: -10,
    timelapse: 7,
    axisZoom: 3.0
  },
  'argentina': {
    selectedCelestialBody: 'Earth',
    zoom: 61,
    axisD: -40,
    timelapse: 7,
    axisZoom: 3.0
  },
  'egypt': {
    selectedCelestialBody: 'Earth',
    zoom: 323,
    axisD: 26,
    timelapse: 7,
    axisZoom: 3.0
  },
  'netherlands': {
    selectedCelestialBody: 'Earth',
    zoom: 347,
    axisD: 49,
    timelapse: 7,
    axisZoom: 3.0
  },
  'switzerland': {
    selectedCelestialBody: 'Earth',
    zoom: 347,
    axisD: 59,
    timelapse: 7,
    axisZoom: 3.0
  },
  'sweden': {
    selectedCelestialBody: 'Earth',
    zoom: 347,
    axisD: 65,
    timelapse: 7,
    axisZoom: 3.0
  },
  'poland': {
    selectedCelestialBody: 'Earth',
    zoom: 347,
    axisD: 60,
    timelapse: 7,
    axisZoom: 3.0
  },
  'belgium': {
    selectedCelestialBody: 'Earth',
    zoom: 348,
    axisD: 58,
    timelapse: 7,
    axisZoom: 3.0
  },
  'norway': {
    selectedCelestialBody: 'Earth',
    zoom: 347,
    axisD: 65,
    timelapse: 7,
    axisZoom: 3.0
  },
  'austria': {
    selectedCelestialBody: 'Earth',
    zoom: 345,
    axisD: 58,
    timelapse: 7,
    axisZoom: 3.0
  },
  'ireland': {
    selectedCelestialBody: 'Earth',
    zoom: 360,
    axisD: 58,
    timelapse: 7,
    axisZoom: 3.0
  },
  'greece': {
    selectedCelestialBody: 'Earth',
    zoom: 342,
    axisD: 39,
    timelapse: 7,
    axisZoom: 3.0
  },
  'portugal': {
    selectedCelestialBody: 'Earth',
    zoom: 7,
    axisD: 32,
    timelapse: 7,
    axisZoom: 3.0
  },
  'new-zealand': {
    selectedCelestialBody: 'Earth',
    zoom: 170,
    axisD: -27,
    timelapse: 7,
    axisZoom: 3.0
  },
  'singapore': {
    selectedCelestialBody: 'Earth',
    zoom: 250,
    axisD: 0,
    timelapse: 7,
    axisZoom: 3.0
  },
  'denmark': {
    selectedCelestialBody: 'Earth',
    zoom: 345,
    axisD: 62,
    timelapse: 7,
    axisZoom: 3.0
  },
  'finland': {
    selectedCelestialBody: 'Earth',
    zoom: 330,
    axisD: 62,
    timelapse: 7,
    axisZoom: 3.0
  },
  // Add settings for general slides
  'conversation': {
    selectedCelestialBody: 'Earth',
    timelapse: 0,
    axisZoom: 10.9,
    vertical: 90
  },
  'thinking': {
    selectedCelestialBody: 'Earth',
    timelapse: 0,
    axisZoom: 10.9,
    vertical: 90
  },
  'group': {
    selectedCelestialBody: 'Earth',
    timelapse: 0,
    axisZoom: 10.9,
    vertical: 90
  },
  'image': {
    selectedCelestialBody: 'Earth',
    timelapse: 0,
    axisZoom: 10.9,
    vertical: 90
  },
  'video': {
    selectedCelestialBody: 'Earth',
    timelapse: 0,
    axisZoom: 10.9,
    vertical: 90
  }
};
