import React, { useMemo } from 'react';
import * as THREE from 'three';

const Mars = () => {
  const marsShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          float angle = radians(25.2);
          mat3 rotationMatrix = mat3(
            1.0, 0.0, 0.0,
            0.0, cos(angle), -sin(angle),
            0.0, sin(angle), cos(angle)
          );
          
          vec3 rotatedPosition = rotationMatrix * position;
          vec3 rotatedNormal = rotationMatrix * normal;
          
          vUv = uv;
          vNormal = rotatedNormal;
          vPosition = rotatedPosition;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(rotatedPosition, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - 1.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.8, 0.3, 0.2); // Rusty red base
          
          float angle = atan(vPosition.z, vPosition.x);
          float wave1 = sin(angle * 6.0 + time * 0.2) * 0.02;
          float wave2 = sin(angle * 4.0 + time * 0.15 + PI * 0.8) * 0.015;
          float wave3 = sin(angle * 3.0 - time * 0.3 + PI * 0.4) * 0.01;
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float terrain = noise(vec2(modifiedY * 2.0, time * 0.1)) * 0.7;
          terrain += noise(vec2(modifiedY * 16.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          if (modifiedY > 0.8) {
              bandColor = mix(
                  vec3(0.75, 0.25, 0.15),    // Dark rust
                  vec3(0.85, 0.35, 0.25),    // Light rust
                  terrain
              );
          } else if (modifiedY > 0.6) {
              bandColor = mix(
                  vec3(0.7, 0.3, 0.2),       // Medium rust
                  vec3(0.8, 0.4, 0.3),       // Lighter rust
                  terrain
              );
          } else if (modifiedY > 0.4) {
              bandColor = mix(
                  vec3(0.65, 0.25, 0.15),    // Dark orange-red
                  vec3(0.75, 0.35, 0.25),    // Light orange-red
                  terrain
              );
          } else if (modifiedY > 0.2) {
              bandColor = mix(
                  vec3(0.7, 0.28, 0.18),     // Medium orange-red
                  vec3(0.8, 0.38, 0.28),     // Light orange-red
                  terrain
              );
          } else {
              bandColor = mix(
                  vec3(0.65, 0.22, 0.12),    // Dark rust
                  vec3(0.75, 0.32, 0.22),    // Medium rust
                  terrain
              );
          }
          
          bandColor *= mix(
              vec3(1.0, 0.95, 0.9),     // Warm tint
              vec3(0.95, 0.9, 0.85),    // Dusty tint
              sin(modifiedY * 6.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.2);
          
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          float dotProduct = dot(worldNormal, toSun);
          
          if (dotProduct < 0.9) {
              float angle = acos(-dotProduct) * 2.0;
              vec4 currentColor = vec4(finalColor, 1.0);
              
              if (angle < 3.3) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.00, 1.0), 0.5);
              }
              if (angle < 3.0) {
                  currentColor = mix(currentColor, vec4(0.0, 0.00, 0.00, 1.0), 0.5);
              }
              
              finalColor = currentColor.rgb;
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <group>
      <mesh renderOrder={2}>
        <sphereGeometry args={[3389.5, 80, 80]} />
        <shaderMaterial 
          attach="material"
          {...marsShader}
          side={THREE.FrontSide}
        />
      </mesh>

    </group>
  );
};

export default Mars;
