import React, { useMemo } from 'react';
import * as THREE from 'three';

const Saturn = () => {
  const saturnShader = useMemo(() => {
    return {
      uniforms: {
        time: { value: 0 },
        modelMatrix: { value: new THREE.Matrix4() },
      },
      vertexShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        
        void main() {
          vUv = uv;
          vNormal = normal;
          vPosition = position;
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        }
      `,
      fragmentShader: `
        varying vec2 vUv;
        varying vec3 vNormal;
        varying vec3 vPosition;
        uniform float time;
        uniform mat4 modelMatrix;

        const float PI = 3.14159265359;

        float random(vec2 st) {
          return fract(sin(dot(st.xy, vec2(12.9898,78.233))) * 43758.5453123);
        }
        
        float noise(vec2 st) {
          vec2 i = floor(st);
          vec2 f = fract(st);
          float a = random(i);
          float b = random(i + vec2(1.0, 0.0));
          float c = random(i + vec2(0.0, 1.0));
          float d = random(i + vec2(1.0, 1.0));
          vec2 u = f * f * (1.0 - 1.0 * f);
          return mix(a, b, u.x) + (c - a)* u.y * (1.0 - u.x) + (d - b) * u.x * u.y;
        }

        void main() {
          vec3 baseColor = vec3(0.96, 0.64, 0.38); // Sandy color for Saturn
          
          // Calculate angle around the sphere for seamless waves
          float angle = atan(vPosition.z, vPosition.x);
          // Using multiples of 2PI to ensure complete cycles
          float wave1 = sin(angle * 8.0 + time * 0.5) * 0.05;  // 8 complete waves
          float wave2 = sin(angle * 6.0 + time * 0.3 + PI * 2.7) * 0.03;  // 6 complete waves
          float wave3 = sin(angle * 4.0 - time * 0.4 + PI * 1.8) * 0.02;  // 4 complete waves
          float modifiedY = vUv.y + wave1 + wave2 + wave3;
          
          float bands = noise(vec2(modifiedY * 1.0, time * 0.1)) * 0.7;
          bands += noise(vec2(modifiedY * 25.0, time * 0.05)) * 0.3;
          
          vec3 bandColor;
          
          // Create two special layers with the new color (#07AEA7)
          // and add middle stripes with #09B7CF
          if (modifiedY > 0.6 && modifiedY < 0.7) {
              if (modifiedY > 0.63 && modifiedY < 0.67) {
                  bandColor = mix(
                      vec3(0.353, 0.424, 0.898),  // #5A6CE5
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              } else {
                  bandColor = mix(
                      vec3(0.027, 0.682, 0.655),  // #07AEA7
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              }
          } else if (modifiedY > 0.3 && modifiedY < 0.4) {
              if (modifiedY > 0.33 && modifiedY < 0.37) {
                  bandColor = mix(
                      vec3(0.353, 0.424, 0.898),  // #5A6CE5
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              } else {
                  bandColor = mix(
                      vec3(0.027, 0.682, 0.655),  // #07AEA7
                      vec3(0.98, 0.84, 0.65),     // Light cream
                      bands
                  );
              }
          } else {
              bandColor = mix(
                  vec3(0.82, 0.53, 0.28),     // Original darker orange-brown
                  vec3(0.98, 0.84, 0.65),     // Light cream
                  bands
              );
          }
          
          // Add some golden and reddish tints
          bandColor *= mix(
              vec3(1.0, 0.9, 0.7),     // Golden tint
              vec3(1.0, 0.85, 0.8),    // Slight reddish tint
              sin(modifiedY * 8.0) * 0.5 + 0.5
          );
          
          vec3 finalColor = mix(bandColor, baseColor, 0.2);
          
          // Calculate world position and direction to sun (0,0,0)
          vec3 worldPos = (modelMatrix * vec4(vPosition, 1.0)).xyz;
          vec3 toSun = normalize(-worldPos);
          vec3 worldNormal = normalize(mat3(modelMatrix) * vNormal);
          
          // Calculate dot product between normal and direction to sun
          float dotProduct = dot(worldNormal, toSun);
          
          // Add shading spots on dark side
          if (dotProduct < 0.9) {
              float angle = acos(-dotProduct) * 2.0;
              
              // Base color
              vec4 currentColor = vec4(finalColor, 1.0);
              
              // spot (outer ring)
              if (angle < 3.3) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }
              
              // spot (middle ring)
              if (angle < 3.0) {
                  currentColor = mix(currentColor, vec4(0.0, 0.0, 0.0, 1.0), 0.5);
              }              
              finalColor = currentColor.rgb;
          }
          
          gl_FragColor = vec4(finalColor, 1.0);
        }
      `
    };
  }, []);

  return (
    <>
      <mesh renderOrder={2}>
        <sphereGeometry args={[58232, 64, 64]} />
        <shaderMaterial 
          attach="material"
          {...saturnShader}
          side={THREE.FrontSide}
        />
      </mesh>

      {/* Innermost ring - Light Blue */}
      <mesh rotation={[Math.PI / 3, 0, 0]}>
        <torusGeometry args={[80000, 5000, 2, 64]} />
        <meshBasicMaterial color="#ADD8E6" transparent opacity={1} />
      </mesh>
      {/* Blue ring */}
      <mesh rotation={[Math.PI / 3, 0, 0]}>
        <torusGeometry args={[90000, 5000, 2, 64]} />
        <meshBasicMaterial color="#4169E1" transparent opacity={1} />
      </mesh>
      {/* Deep Blue ring */}
      <mesh rotation={[Math.PI / 3, 0, 0]}>
        <torusGeometry args={[100000, 5000, 2, 64]} />
        <meshBasicMaterial color="#ADD8E6" transparent opacity={1} />
      </mesh>
      {/* Light Purple ring */}
      <mesh rotation={[Math.PI / 3, 0, 0]}>
        <torusGeometry args={[110000, 5000, 2, 64]} />
        <meshBasicMaterial color="#9370DB" transparent opacity={1} />
      </mesh>
      {/* Medium Purple ring */}
      <mesh rotation={[Math.PI / 3, 0, 0]}>
        <torusGeometry args={[120000, 5000, 2, 64]} />
        <meshBasicMaterial color="#ADD8E6" transparent opacity={1} />
      </mesh>
      {/* Deep Purple ring */}
      <mesh rotation={[Math.PI / 3, 0, 0]}>
        <torusGeometry args={[130000, 5000, 2, 64]} />
        <meshBasicMaterial color="#4169E1" transparent opacity={1} />
      </mesh>
      
      {/* Outer atmosphere layer */}
      <mesh renderOrder={1}>
        <sphereGeometry args={[62000, 128, 640]} />
        <meshBasicMaterial 
          color="#f4d03f"
          transparent={true}
          opacity={0.1}
          side={THREE.BackSide}
          depthWrite={true}
          depthTest={true}
        />
      </mesh>

      {/* Inner atmosphere layer */}
      <mesh renderOrder={-1}>
        <sphereGeometry args={[59000, 128, 640]} />
        <meshBasicMaterial 
          color="#f4d03f"
          transparent={true}
          opacity={0.01}
          side={THREE.BackSide}
          depthWrite={true}
          depthTest={true}
        />
      </mesh>
    </>
  );
};

export default Saturn;
