// src/components/Teachers/Slideshow/SlidesCreator.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from './Button'; // Adjust the path based on your project structure
import PixiCanvas from '../../../pixi/ThreeJS'; // Adjust the path based on your project structure
import {
  Dial,
  Slider,
  slideTemplates,
  locationCoordinates,
  planetLabels,
  hasMoons,
  getMoonsForPlanet,
  generateInitialSlides,
  generateInitialLocationTextMatrix,
  slideSettings
} from './SlideCreatorData';

// Update to softer, pastel colors for each tab
const tabColors = {
  Space: "#FFD6A5",      // Soft orange
  Continents: "#DDD6FE", // Soft purple
  Countries: "#BFDBFE",  // Soft blue
  Cities: "#93C5FD",     // Light blue
  General: "#BAE6FD",    // Softer blue
  Classroom: "#FDE68A",  // Soft yellow
  Office: "#E5E7EB",     // Soft gray
  Desk: "#D1D5DB",      // Light gray
  Body: "#FCA5A5",      // Soft red
  Shop: "#FCD34D",      // Soft amber
  Hospital: "#A7F3D0",   // Soft green
  Movies: "#DDD6FE",     // Soft purple
  Park: "#A7F3D0",      // Soft green
  Home: "#FDE68A",      // Soft yellow
  Street: "#E5E7EB",     // Soft gray
  Beach: "#FDE68A",      // Soft yellow
  Sea: "#BAE6FD",       // Soft blue
  Landscape: "#E5E7EB",  // Soft gray
  Data: "#C7D2FE",      // Soft indigo
  Interactive: "#FCE7F3", // Soft pink
  "Time/Date": "#D1D5DB", // Light gray
  Custom: "#F3F4F6"      // Lightest gray
};

// Replace the complex textColors object with a single dark gray color
const textColors = {
  Space: "#374151",      // Dark gray
  Continents: "#374151", // Dark gray
  Countries: "#374151",  // Dark gray
  Cities: "#374151",     // Dark gray
  General: "#374151",    // Dark gray
  Classroom: "#374151",  // Dark gray
  Office: "#374151",     // Dark gray
  Desk: "#374151",      // Dark gray
  Body: "#374151",      // Dark gray
  Shop: "#374151",      // Dark gray
  Hospital: "#374151",   // Dark gray
  Movies: "#374151",     // Dark gray
  Park: "#374151",      // Dark gray
  Home: "#374151",      // Dark gray
  Street: "#374151",     // Dark gray
  Beach: "#374151",      // Dark gray
  Sea: "#374151",       // Dark gray
  Landscape: "#374151",  // Dark gray
  Data: "#374151",      // Dark gray
  Interactive: "#374151", // Dark gray
  "Time/Date": "#374151", // Dark gray
  Custom: "#374151"      // Dark gray
};

const SlideCreator = ({ onClose, onPopSlide }) => {
  // Replace the static genres array with a simpler one
  const genres = ['Blank'];

  // Placeholder subtitles with empty strings
  const placeholderSubtitles = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];

  // Change default active tab to Space
  const [activeTab, setActiveTab] = useState('Space');

  // State declarations
  const [slides, setSlides] = useState(() => {
    // These initial slides come from the Continents group.
    const initialContinents = [
      { theme: 'asia', preview: '🌏', name: 'Asia' },
      { theme: 'antarctica', preview: '🗺️', name: 'Antarctica' },
      { theme: 'north-america', preview: '🌎', name: 'North America' },
      { theme: 'south-america', preview: '🌎', name: 'South America' },
      { theme: 'europe', preview: '🌍', name: 'Europe' },
      { theme: 'africa', preview: '🌍', name: 'Africa' },
      { theme: 'oceania', preview: '🌏', name: 'Oceania' },
    ];
    
    return initialContinents.map((continent, index) => ({
      id: Date.now() + index,
      subtitle: "",
      x: 0,
      y: 0,
      z: 0,
      location: 'Field',
      theme: continent.theme,
      // Instead of cycling through an array, use the continent group color
      color: tabColors["Continents"],
      preview: continent.preview,
      name: continent.name,
      themeNumber: 1,
      sequenceNumber: index + 1
    }));
  });
  const [locationTextMatrix, setLocationTextMatrix] = useState(generateInitialLocationTextMatrix());

  // State to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // State to track navigation direction for animation
  const [navigationDirection, setNavigationDirection] = useState(0); // 1 for next, -1 for previous, 0 for direct navigation

  // New state to track if a transition is in progress
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Add this new state near the top of the component with other state declarations
  const [showCoordinates, setShowCoordinates] = useState(false);

  // Make "show/hide matrix" hidden by default
  const [showMatrix, setShowMatrix] = useState(false);

  // Add this new state near other state declarations
  const [showText, setShowText] = useState(true);

  // Add new state for HUD controls
  const [speed, setSpeed] = useState(50);
  const [orbitLock, setOrbitLock] = useState(false);
  const [guidanceSystem, setGuidanceSystem] = useState(false);
  const [timelapse, setTimelapse] = useState(0);

  // Add new state for View Lock
  const [viewLock, setViewLock] = useState(false);

  // Add new state for selected celestial body
  const [selectedCelestialBody, setSelectedCelestialBody] = useState('Sun');

  // Add new state for showing moons
  const [showMoons, setShowMoons] = useState(false);

  // Add new state for selected moon
  const [selectedMoon, setSelectedMoon] = useState('');

  // Add ref for scene initialization status
  const [sceneInitialized, setSceneInitialized] = useState(false);

  // Add new state for the flight sim controls
  const [gravity, setGravity] = useState(true);
  const [gravityAssist, setGravityAssist] = useState(false);
  const [land, setLand] = useState(false);

  // Add new state for zoom control near other state declarations
  const [zoom, setZoom] = useState(0);

  // Add new state for axis D control near other state declarations
  const [axisD, setAxisD] = useState(0);

  // Add new state for template selector
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);

  // Change default state for HUD visibility to false
  const [showHud, setShowHud] = useState(false);

  // Add new state declarations near the other state declarations
  const [universeScale, setUniverseScale] = useState(1);
  const [universeDistance, setUniverseDistance] = useState(0);

  // Add new state declarations near the other state declarations
  const [axisZoom, setAxisZoom] = useState(.7);
  const [vertical, setVertical] = useState(0);

  // Add new state for horizontal control near other state declarations
  const [horizontal, setHorizontal] = useState(0);

  const currentSlide = slides[currentSlideIndex];

  // Function to handle animation completion
  const handleAnimationComplete = () => {
    setIsTransitioning(false);
  };

  // Updated handlers for navigating slides
  const handleNext = () => {
    if (currentSlideIndex >= slides.length - 1) return;
    const nextSlide = slides[currentSlideIndex + 1];
    applySlideSettings(nextSlide);
    setCurrentSlideIndex(currentSlideIndex + 1);
  };

  const handlePrevious = () => {
    if (currentSlideIndex === 0) return;
    const prevSlide = slides[currentSlideIndex - 1];
    applySlideSettings(prevSlide);
    setCurrentSlideIndex(currentSlideIndex - 1);
  };

  // Update handleSlideClick to also handle static/orbit views.
  const handleSlideClick = (index) => {
    if (index === currentSlideIndex) return;
    const targetSlide = slides[index];
    applySlideSettings(targetSlide);
    setCurrentSlideIndex(index);
  };

  // Modify handleAddSlide to apply settings for new slides
  const handleAddSlide = (template) => {
    const newSlide = {
      id: Date.now(),
      subtitle: "",
      x: 0,
      y: 0,
      z: 0,
      location: 'Field',
      theme: template.theme,
      settings: slideSettings[template.theme] || {},
      color: tabColors[activeTab],
      textColor: textColors[activeTab],
      preview: template.preview,
      name: template.name
    };

    const updatedSlides = [...slides, newSlide];
    setSlides(updatedSlides);
    
    // Update current slide index to the new slide
    const newIndex = updatedSlides.length - 1;
    setCurrentSlideIndex(newIndex);
    
    // Apply the slide settings immediately
    const settings = slideSettings[template.theme];
    if (settings) {
      // Apply all settings at once
      setSelectedCelestialBody(settings.selectedCelestialBody);
      setZoom(settings.zoom ?? 0);
      setAxisD(settings.axisD ?? 0);
      setAxisZoom(settings.axisZoom ?? 1);
      setTimelapse(settings.timelapse ?? 0);
      if (settings.showMoons !== undefined) setShowMoons(settings.showMoons);
      if (settings.selectedMoon !== undefined) setSelectedMoon(settings.selectedMoon);
      if (settings.vertical !== undefined) setVertical(settings.vertical);
    }
  };

  // Simplify handleDeleteSlide by removing title recalculation
  const handleDeleteSlide = (index, e) => {
    e.stopPropagation(); // Prevent slide selection when clicking delete
    
    const updatedSlides = slides.filter((_, i) => i !== index);
    setSlides(updatedSlides);
    
    // Adjust current slide index if necessary
    if (index <= currentSlideIndex) {
      setCurrentSlideIndex(Math.max(0, currentSlideIndex - 1));
    }
  };

  // Add function to get highest theme number
  const getHighestThemeNumber = () => {
    if (!slides.length) return 1;
    return Math.max(...slides.map(slide => slide.themeNumber));
  };

  // Add coordinate change handler
  const handleCoordinateChange = (coord, value) => {
    const updatedSlides = [...slides];
    updatedSlides[currentSlideIndex] = {
      ...slides[currentSlideIndex],
      [coord]: Number(value),
      axisZoom: axisZoom
    };
    setSlides(updatedSlides);
  };

  // Add this handler near other handlers
  const handleLocationChange = (e) => {
    const location = e.target.value;
    const coordinates = locationCoordinates[location];
    
    if (coordinates) {
      const updatedSlides = [...slides];
      updatedSlides[currentSlideIndex] = {
        ...slides[currentSlideIndex],
        x: coordinates.x,
        y: coordinates.y,
        z: coordinates.z,
        axisZoom: axisZoom,
        location: location,
        title: `${location} ${currentSlide.themeNumber}.${currentSlide.sequenceNumber} ${currentSlide.theme}`
      };
      setSlides(updatedSlides);
      setLocationTextMatrix(generateInitialLocationTextMatrix());
    }
  };

  // Add this useEffect to handle key events
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Prevent default behaviour for WASD and Space keys
      if (['w', 'a', 's', 'd', ' '].includes(e.key.toLowerCase())) {
        e.preventDefault();
      }

      // Handle speed adjustments
      if (e.key === '-' || e.key === '_') {
        setSpeed(prevSpeed => Math.max(0, prevSpeed - 5));
      }
      if (e.key === '+' || e.key === '=') {
        setSpeed(prevSpeed => Math.min(100, prevSpeed + 5));
      }
    };

    // Add the event listener
    window.addEventListener('keydown', handleKeyDown);

    // Clean up
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array since we don't need any dependencies

  useEffect(() => {
    // Remove all console.logs
  }, [
    guidanceSystem,
    orbitLock,
    viewLock,
    gravity,
    gravityAssist,
    land,
    selectedCelestialBody,
    speed,
    timelapse,
    zoom,
    axisD,
    axisZoom
  ]);

  // Add this useEffect near the other useEffect hooks
  useEffect(() => {
    if (slides.length > 0) {
      const initialSlide = {
        ...slides[0],
        axisZoom: slides[0].axisZoom || 1
      };
      applySlideSettings(initialSlide);
    }
  }, []); // Empty dependency array means this runs once when component mounts

  const applySlideSettings = (slide) => {
    const generalSlideTypes = ['conversation', 'thinking', 'group', 'image', 'video'];

    if (generalSlideTypes.includes(slide.theme)) {
      // Apply all changes at once instead of using setTimeout
      setSelectedCelestialBody('Earth');
      setVertical(90);
      setAxisZoom(10.9);
      return;
    }

    // For non-general slides, retrieve settings.
    const settings = slideSettings[slide.theme];
    if (!settings) return;

    // Determine if the slide is a Continent or Country slide.
    const isContinentSlide = slideTemplates.Continents.some(
      (template) => template.theme === slide.theme
    );
    const isCountrySlide = slideTemplates.Countries.some(
      (template) => template.theme === slide.theme
    );

    // If it's a Continent or Country slide, update everything together.
    if (isContinentSlide || isCountrySlide) {
      // Update selected celestial body immediately (for example, to "Earth").
      setSelectedCelestialBody(settings.selectedCelestialBody);
      setZoom(settings.zoom ?? 0);
      setAxisD(settings.axisD ?? 0);
      // Set axisZoom based on slide type.
      if (isContinentSlide) {
        setAxisZoom(1);
      } else if (isCountrySlide) {
        setAxisZoom(3);
      }
      setTimelapse(settings.timelapse ?? 0);
      // For Earth slides, vertical is set to 0 (no 90° offset).
      setVertical(0);
      return;
    }

    // For any other slide types, update selectedCelestialBody immediately
    // and the rest after a slight delay.
    setSelectedCelestialBody(settings.selectedCelestialBody);
    setTimeout(() => {
      setZoom(settings.zoom ?? 0);
      setAxisD(settings.axisD ?? 0);
      setAxisZoom(settings.axisZoom ?? 1);
      setTimelapse(settings.timelapse ?? 0);
      if (settings.showMoons !== undefined) setShowMoons(settings.showMoons);
      if (settings.selectedMoon !== undefined) setSelectedMoon(settings.selectedMoon);
    }, 50);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-60">
      {/* Template Selector Modal */}
      {showTemplateSelector && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-[90vw] h-[80vh] bg-white rounded-lg shadow-xl flex flex-col">
            <div className="flex items-center justify-between p-4 border-b">
              <h3 className="text-2xl font-semibold">Choose a Template</h3>
              <button 
                onClick={() => setShowTemplateSelector(false)}
                className="text-2xl text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>
            
            {/* Tabs */}
            <div className="flex p-2 space-x-1 overflow-x-auto bg-gray-100">
              {Object.keys(slideTemplates).map((tab) => (
                <button
                  key={tab}
                  onClick={() => setActiveTab(tab)}
                  className={`px-4 py-2 rounded-lg text-sm font-medium transition-colours
                    ${activeTab === tab 
                      ? 'bg-white text-blue-600 shadow' 
                      : 'text-gray-600 hover:bg-white hover:text-blue-600'
                    }`}
                >
                  {tab}
                </button>
              ))}
            </div>

            {/* Template Grid */}
            <div className="flex-1 p-6 overflow-y-auto">
              <div className="grid grid-cols-6 gap-2">
                {slideTemplates[activeTab].map((template) => (
                  <button
                    key={template.id}
                    onClick={() => handleAddSlide(template)}
                    className="flex flex-col items-center p-2 space-y-1 transition-all border rounded-lg hover:scale-105 hover:shadow-lg"
                    style={{ 
                      // Use our uniform tab colour for the template button background.
                      backgroundColor: tabColors[activeTab],
                      color: textColors[activeTab],
                      minHeight: '80px'
                    }}
                  >
                    <span className="text-xl">{template.preview}</span>
                    <span className="w-full text-xs font-medium text-center truncate">{template.name}</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Fullscreen Modal Container */}
      <div className="flex flex-col w-full h-full bg-white rounded-none shadow-none md:flex-row">
        
        {/* Left Panel (Slides List) - Adjusted width */}
        <div className="flex flex-col w-full p-4 overflow-y-auto border-r md:w-64">
          <h4 className="mb-4 text-lg font-medium text-center">Slides</h4>
          <ul className="flex flex-col items-center flex-1 space-y-4">
            {slides.map((slide, index) => (
              <li
                key={slide.id}
                onClick={() => handleSlideClick(index)}
                className={`cursor-pointer rounded w-48 relative ${
                  currentSlideIndex === index
                    ? 'border-blue-500 shadow-lg scale-105'
                    : 'border-gray-200 hover:scale-105 hover:shadow-lg'
                }`}
                style={{ 
                  backgroundColor: slide.color,
                  color: '#374151', // Changed to dark gray text for better contrast
                  transition: 'all 0.2s'
                }}
              >
                <div className="flex items-center justify-center p-4">
                  <div className="flex flex-col items-center space-y-2">
                    <span className="text-2xl">{slide.preview}</span>
                    <span className="text-sm font-medium">{slide.theme}</span>
                  </div>
                  
                  <button
                    onClick={(e) => handleDeleteSlide(index, e)}
                    className="absolute text-current opacity-75 hover:opacity-100 right-2 top-2"
                    title="Delete slide"
                  >
                    ×
                  </button>
                </div>
              </li>
            ))}
            {/* Add New Slide Tile */}
            <li
              onClick={() => setShowTemplateSelector(true)}
              className="w-48 border border-gray-300 border-dashed rounded cursor-pointer hover:border-blue-500 hover:bg-blue-50"
            >
              <div className="flex items-center justify-center p-4">
                <span className="text-2xl text-gray-400 hover:text-blue-500">+</span>
              </div>
            </li>
          </ul>
        </div>

        {/* Right Panel - Adjusted to take remaining space */}
        <div className="flex flex-col w-full h-full p-6 overflow-hidden md:flex-1">
          {/* Header with Close Button */}
          <div className="flex items-center justify-end mb-4">
            <Button
              onClick={onClose}
              variant="ghost"
              className="ml-4 text-2xl leading-none"
              aria-label="Close Slide Creator"
              disabled={isTransitioning}
            >
              &times;
            </Button>
          </div>

          {currentSlide && (
            <div className="flex flex-col justify-between h-full space-y-4">
              {/* PixiCanvas Integration */}
              <div className="flex flex-col items-center justify-center flex-1 p-4 overflow-hidden bg-gray-100 border rounded-md" 
                   style={{ minHeight: 0 }}>
                <div className="relative w-full h-full">
                  <div className="absolute inset-0">
                    {/* Only render PixiCanvas when scene is ready to be initialized */}
                    {currentSlide && (
                      <PixiCanvas
                        width={1600}
                        height={900}
                        backgroundColor={0x1099bb}
                        currentSlide={currentSlideIndex + 1}
                        totalSlides={slides.length}
                        speed={speed}
                        orbitLock={orbitLock}
                        guidanceSystem={guidanceSystem}
                        timelapse={timelapse}
                        viewLock={viewLock}
                        selectedCelestialBody={selectedCelestialBody}
                        selectedMoon={selectedMoon}
                        onSceneReady={() => setSceneInitialized(true)}
                        zoom={zoom}
                        axisD={axisD}
                        gravity={gravity}
                        gravityAssist={gravityAssist}
                        land={land}
                        axisZoom={axisZoom}
                        vertical={vertical}
                        horizontal={horizontal}
                      />
                    )}
                    
                    {/* Conditional Text Overlay */}
                    {currentSlide.subtitle && showText && (
                      <div 
                        className={`absolute w-4/5 max-w-2xl transform -translate-x-1/2 bottom-20 left-1/2 transition-opacity duration-500 ${
                          showText ? 'opacity-100' : 'opacity-0'
                        }`}
                      >
                        <div className="p-4 bg-white rounded-lg shadow-lg bg-opacity-90 backdrop-blur-sm">
                          <p className="text-lg font-light leading-relaxed text-center text-gray-700">
                            {currentSlide.subtitle}
                          </p>
                        </div>
                      </div>
                    )}

                    {/* HUD Controls */}
                    <div className="absolute z-50 bottom-4 right-4">
                      {showHud ? (
                        <div className="w-64 p-4 font-mono text-green-400 bg-black rounded-lg bg-opacity-40 backdrop-blur-sm">
                          {/* Close button */}
                          <div className="flex justify-end mb-2">
                            <button
                              onClick={() => setShowHud(false)}
                              className="text-green-400 hover:text-green-300"
                            >
                              ✕
                            </button>
                          </div>

                          {/* New Quick Control Buttons */}
                          <div className="grid grid-cols-2 gap-2 mb-4">
                            <button
                              onClick={() => {
                                setVertical(90);
                                // Add a small delay before setting axisZoom
                                setTimeout(() => {
                                  setAxisZoom(10.9);
                                }, 100);
                              }}
                              className="px-3 py-1 border border-green-400 rounded hover:bg-green-400 hover:bg-opacity-20"
                            >
                              <div className="text-xs">LAND</div>
                              <div className="text-sm font-bold">VIEW</div>
                            </button>

                            <button
                              onClick={() => {
                                setVertical(0);
                                // Add a small delay before setting axisZoom
                                setTimeout(() => {
                                  setAxisZoom(3);
                                }, 100);
                              }}
                              className="px-3 py-1 border border-green-400 rounded hover:bg-green-400 hover:bg-opacity-20"
                            >
                              <div className="text-xs">COUNTRY</div>
                              <div className="text-sm font-bold">VIEW</div>
                            </button>

                            <button
                              onClick={() => {
                                setVertical(0);
                                // Add a small delay before setting axisZoom
                                setTimeout(() => {
                                  setAxisZoom(1);
                                }, 100);
                              }}
                              className="px-3 py-1 border border-green-400 rounded hover:bg-green-400 hover:bg-opacity-20"
                            >
                              <div className="text-xs">CONTINENT</div>
                              <div className="text-sm font-bold">VIEW</div>
                            </button>
                          </div>

                          {/* Existing Control Buttons */}
                          <div className="grid grid-cols-2 gap-2 mb-4">
                            <button
                              onClick={() => setGravity(!gravity)}
                              className={`px-3 py-1 border rounded ${
                                gravity 
                                  ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                  : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                              }`}
                            >
                              <div className="text-xs">SOLAR SYSTEM</div>
                              <div className="text-sm font-bold">SIZE</div>
                            </button>

                            <button
                              onClick={() => setGravityAssist(!gravityAssist)}
                              className={`px-3 py-1 border rounded ${
                                gravityAssist 
                                  ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                  : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                              }`}
                            >
                              <div className="text-xs">SOLAR SYSTEM</div>
                              <div className="text-sm font-bold">DISTANCE</div>
                            </button>
                          </div>

                          {/* Planet Selection Buttons */}
                          <div className="mb-4">
                            <div className="flex flex-col gap-1">
                              <button
                                onClick={() => {
                                  setSelectedCelestialBody('Sun');
                                  setShowMoons(false);
                                  setSelectedMoon('');
                                }}
                                className={`px-2 py-1 text-xs border rounded w-20 ${
                                  selectedCelestialBody === 'Sun'
                                    ? 'border-yellow-400 bg-yellow-400 bg-opacity-20' 
                                    : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                                }`}
                              >
                                Sun
                              </button>
                              {['Mercury', 'Venus'].map((planet) => (
                                <div key={planet} className="flex flex-wrap items-center gap-1">
                                  <button
                                    onClick={() => {
                                      setSelectedCelestialBody(planet);
                                      setSelectedMoon('');
                                    }}
                                    className={`px-2 py-1 text-xs border rounded w-20 ${
                                      selectedCelestialBody === planet
                                        ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                        : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                                    }`}
                                  >
                                    {planet}
                                  </button>
                                </div>
                              ))}
                              {/* Earth and Moon buttons */}
                              <div className="flex flex-wrap items-center gap-1">
                                <button
                                  onClick={() => {
                                    setSelectedCelestialBody('Earth');
                                    setSelectedMoon('');
                                  }}
                                  className={`px-2 py-1 text-xs border rounded w-20 ${
                                    selectedCelestialBody === 'Earth' && !selectedMoon
                                      ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                      : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                                  }`}
                                >
                                  Earth
                                </button>
                                <button
                                  onClick={() => {
                                    setSelectedCelestialBody('Moon');
                                    setSelectedMoon('');
                                  }}
                                  className={`px-2 py-1 text-xs border rounded w-20 ${
                                    selectedCelestialBody === 'Moon'
                                      ? 'border-blue-400 bg-blue-400 bg-opacity-20' 
                                      : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                                  }`}
                                >
                                  Moon
                                </button>
                              </div>
                              {/* Remaining planets */}
                              {['Mars', 'Jupiter', 'Saturn', 'Uranus', 'Neptune'].map((planet) => (
                                <div key={planet} className="flex flex-wrap items-center gap-1">
                                  <button
                                    onClick={() => {
                                      setSelectedCelestialBody(planet);
                                      setSelectedMoon('');
                                    }}
                                    className={`px-2 py-1 text-xs border rounded w-20 ${
                                      selectedCelestialBody === planet
                                        ? 'border-green-400 bg-green-400 bg-opacity-20' 
                                        : 'border-green-400 hover:bg-green-400 hover:bg-opacity-20'
                                    }`}
                                  >
                                    {planet}
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>

                          {/* Timelapse Dial with Deadzone */}
                          <div className="relative">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">TIMELAPSE</span>
                              <span className="text-lg">
                                {timelapse > 0 ? '+' : ''}{timelapse.toFixed(0)}
                              </span>
                            </div>
                            <input
                              type="range"
                              min="-50"
                              max="5000"
                              step="1"
                              value={timelapse}
                              onChange={(e) => {
                                const value = parseFloat(e.target.value);
                                const deadzoneThreshold = 1;
                                setTimelapse(Math.abs(value) < deadzoneThreshold ? 0 : value);
                              }}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Axis R Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">AXIS R</span>
                              <span className="text-lg">{zoom.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="360"
                              step="1"
                              value={zoom}
                              onChange={(e) => setZoom(parseFloat(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Axis D Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">AXIS D</span>
                              <span className="text-lg">{axisD.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min="-89"
                              max="89"
                              step="1"
                              value={axisD || 0}
                              onChange={(e) => setAxisD(Number(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Axis Zoom Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">AXIS ZOOM</span>
                              <span className="text-lg">{axisZoom.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min=".1"
                              max="30"
                              step=".01"
                              value={axisZoom}
                              onChange={(e) => setAxisZoom(Number(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Vertical Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">VERTICAL</span>
                              <span className="text-lg">{vertical.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="90"
                              step="1"
                              value={vertical}
                              onChange={(e) => setVertical(Number(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Horizontal Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">HORIZONTAL</span>
                              <span className="text-lg">{horizontal.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min="0"
                              max="90"
                              step="1"
                              value={horizontal}
                              onChange={(e) => setHorizontal(Number(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Universe Scale Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">UNIVERSE SCALE</span>
                              <span className="text-lg">{universeScale.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min="1"
                              max="1000"
                              step="1"
                              value={universeScale}
                              onChange={(e) => setUniverseScale(parseFloat(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>

                          {/* Universe Distance Dial */}
                          <div className="relative mt-4">
                            <div className="flex items-center justify-between">
                              <span className="text-xs">UNIVERSE DISTANCE</span>
                              <span className="text-lg">{universeDistance.toFixed(1)}</span>
                            </div>
                            <input
                              type="range"
                              min="-100"
                              max="1"
                              step="0.1"
                              value={universeDistance}
                              onChange={(e) => setUniverseDistance(parseFloat(e.target.value))}
                              className="w-full h-2 bg-green-900 rounded-lg appearance-none cursor-pointer [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:w-4 [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:bg-green-400 [&::-webkit-slider-thumb]:rounded-full"
                            />
                          </div>
                        </div>
                      ) : (
                        <button
                          onClick={() => setShowHud(true)}
                          className="px-4 py-2 font-mono text-green-400 bg-black rounded-lg bg-opacity-40 backdrop-blur-sm hover:bg-opacity-60"
                        >
                          HUD
                        </button>
                      )}
                    </div>

                    {/* Navigation Buttons */}
                    <div className="absolute left-0 right-0 flex justify-center space-x-4 bottom-4">
                      <button
                        type="button"
                        onClick={handlePrevious}
                        disabled={currentSlideIndex === 0 || isTransitioning}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                          currentSlideIndex === 0 || isTransitioning
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        Previous
                      </button>
                      <button
                        type="button"
                        onClick={handleNext}
                        disabled={currentSlideIndex === slides.length - 1 || isTransitioning}
                        className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none ${
                          currentSlideIndex === slides.length - 1 || isTransitioning
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                        }`}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* New Text Field */}
              <div className="w-full p-4 bg-white border rounded-md">
                <textarea
                  value={currentSlide?.subtitle || ''}
                  onChange={(e) => {
                    const updatedSlides = [...slides];
                    updatedSlides[currentSlideIndex] = {
                      ...currentSlide,
                      subtitle: e.target.value
                    };
                    setSlides(updatedSlides);
                  }}
                  placeholder="Enter slide text here..."
                  className="w-full p-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="3"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

SlideCreator.propTypes = {
  onClose: PropTypes.func.isRequired,
  onPopSlide: PropTypes.func,
};

export default SlideCreator;
